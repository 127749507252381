import { ConstructionOptionPricing } from "./constructionOptionPricing";

export type ConstructionOption = {
  constructionOptionGuid: string;
  constructionSubgroupGuid: string;
  optionName: string;
  optionPricedPer: "u" | "lf" | "plf" | "sf";
  optionPricedFor: "u" | "a";
  sortOrder: number;
  optionPricing: ConstructionOptionPricing[];
};

export type ConstructionOptionPricedPerType = ConstructionOption["optionPricedPer"];
export type ConstructionOptionPricedForType = ConstructionOption["optionPricedFor"];

export const getPricePerConstructionOptions: ConstructionOptionPricedPerType[] = ["u", "lf", "plf", "sf"];

export const getPriceForConstructionOptions: ConstructionOptionPricedForType[] = ["u", "a"];

export const getConstructionOptionPricePerDisplayName = (
  per: ConstructionOptionPricedPerType
): string => {
  const displayName: Record<ConstructionOptionPricedPerType, string> = {
    u: "Unit",
    lf: "Linear Foot",
    plf: "Perimeter Linear Foot",
    sf: "Square Foot",
  };
  return displayName[per];
};

export const getConstructionOptionPricePerShortDisplayName = (
  per: ConstructionOptionPricedPerType
): string => {
  const displayName: Record<ConstructionOptionPricedPerType, string> = {
    u: "",
    lf: "lf",
    plf: "plf",
    sf: "sq ft",
  };
  return displayName[per];
};

export const getConstructionOptionPriceForDisplayName = (
  forType: ConstructionOptionPricedForType
): string => {
  const displayName: Record<ConstructionOptionPricedForType, string> = {
    a: "All Home Types",
    u: "Unique Per Type",
  };
  return displayName[forType];
};
