import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  Chip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { OrderCard } from "../../types/orderCard";
import { AccordionCard } from "../../components/AccordionCard";
import {
  OrderStatusIndicator,
  OrderStatusIndicatorMenuItem,
} from "./OrderStatusIndicator";
import { useOrderCard } from "./OrderCardContext";
import { EmptyState } from "../../components/EmptyState";
import { useInViewport } from "react-in-viewport";
import { FormLabelAsterisk } from "../../components/FormLabelAsterisk";

interface OrderCardBaseProps {
  action?: React.ReactNode;
  children?: NonNullable<React.ReactNode>;
  disabled: boolean;
  orderCard: OrderCard;
  groupIndex: number;
  disclaimerText?: string;
  title?: string;
  subtitle?: string;
  groupError?: React.ReactNode;
  subgroupError?: React.ReactNode;
  chipLabel?: string;
  isRequired?: boolean;
  expanded?: boolean;
  heading?: React.ReactNode;
  endActions?: React.ReactNode;
  hasLogicError?: boolean;
  onClearClick?: () => void;
  onDeleteClick?: () => void;
  additionalIndicatorMenuOptions?: OrderStatusIndicatorMenuItem[];
  customOptionSelectedVisuals?: boolean;
}

export const OrderCardBase: React.FC<OrderCardBaseProps> = ({
  groupError,
  subgroupError,
  action,
  children,
  disabled,
  orderCard,
  groupIndex,
  disclaimerText,
  title = orderCard.subgroupName,
  subtitle,
  chipLabel,
  isRequired = orderCard.isRequired,
  onClearClick,
  onDeleteClick,
  expanded,
  heading,
  endActions,
  hasLogicError,
  additionalIndicatorMenuOptions,
  customOptionSelectedVisuals,
}) => {
  const { isAutoSaved, onCardInView, jumpToCardGuid } = useOrderCard();
  const baseRef = useRef<HTMLElement | null>(null);
  const { inViewport } = useInViewport(baseRef, {
    threshold: 0,
    rootMargin: "-25% 0px -70% 0px",
  });

  useEffect(() => {
    if (baseRef.current && orderCard.orderCardGuid === jumpToCardGuid) {
      const headerOffset = 220;
      const elementPosition = baseRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [jumpToCardGuid, orderCard.orderCardGuid]);

  useEffect(() => {
    if (inViewport) {
      onCardInView(orderCard);
    }
  }, [inViewport, orderCard, onCardInView]);

  const isFirstCardInGroup = groupIndex === 0;

  const hasErrors = orderCard.errors.length > 0 || hasLogicError === true;

  const orderCardIsCustom =
    orderCard.orderCardType === "cs" ||
    orderCard.orderCardType === "co" ||
    (orderCard.orderCardType === "so" &&
      orderCard.cardData.customOptionSelected);

  const [borderColor, setBorderColor] = useState<string | undefined>(hasErrors
    ? "error.light"
    : orderCardIsCustom
    ? "primary.main"
    : undefined);

  useEffect(() => {
    if (customOptionSelectedVisuals !== undefined) {
      setBorderColor(customOptionSelectedVisuals
        ? "primary.main"
        : hasErrors
        ? "error.light"
        : undefined);
    }
  },[customOptionSelectedVisuals, setBorderColor, hasErrors]);

  return !orderCard ? (
    <EmptyState />
  ) : (
    <Box position="relative" ref={baseRef}>
      {isAutoSaved ? (
        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            right: 0,
            top: isFirstCardInGroup ? "24px" : "-20px",
          }}
        >
          auto saved
        </Typography>
      ) : null}
      {isFirstCardInGroup ? (
        <Stack direction="row" justifyContent="space-between" marginBottom={2}>
          <Stack direction="row" spacing={1}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              color="text.primary"
            >
              {orderCard.groupName}
            </Typography>
            <Typography variant="subtitle1" color="error">
              {groupError}
            </Typography>
          </Stack>
          {action}
        </Stack>
      ) : null}
      {children !== undefined ? (
        <AccordionCard
          expanded={expanded}
          accordionSx={{
            borderLeftWidth: borderColor ? "5px" : undefined,
            borderLeftStyle: borderColor ? "solid" : undefined,
            borderLeftColor: borderColor,
            margin: 0,
          }}
          headingHtml={
            heading ?? (
              <>
                <Stack>
                  <Stack>
                    <Typography variant="body1">
                      {title}
                      {isRequired ? (
                        <span style={{ position: "relative", top: -4 }}>
                          <FormLabelAsterisk />
                        </span>
                      ) : null}
                      {chipLabel ? (
                        <Chip
                          label={chipLabel}
                          size="small"
                          color="primary"
                          variant="filled"
                          component="span"
                          sx={({ palette }) => ({
                            backgroundColor: palette.primary.light,
                            color: palette.primary.main,
                            marginLeft: 0.5,
                          })}
                        />
                      ) : null}
                    </Typography>
                    {subgroupError && (
                      <Typography variant="body2" color="error">
                        {subgroupError}
                      </Typography>
                    )}
                  </Stack>
                  {subtitle ? (
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="text.secondary"
                    >
                      {subtitle}
                    </Typography>
                  ) : null}
                </Stack>
              </>
            )
          }
          defaultExpanded
          endActions={
            endActions || (
              <Stack direction="row" alignItems="center" spacing={1}>
                {disclaimerText ? (
                  <DisclaimerTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          fontSize="12px"
                          color="grey.700"
                          fontWeight={600}
                        >
                          Disclaimer
                        </Typography>
                        <Typography fontSize="12px" color="grey.500">
                          {disclaimerText}
                        </Typography>
                      </React.Fragment>
                    }
                    arrow
                  >
                    <InfoOutlinedIcon sx={{ color: "grey.500" }} />
                  </DisclaimerTooltip>
                ) : null}
                <OrderStatusIndicator
                  disabled={disabled}
                  orderCard={orderCard}
                  hasErrors={hasErrors}
                  onClearClick={onClearClick}
                  onDeleteClick={onDeleteClick}
                  additionalIndicatorMenuOptions={
                    additionalIndicatorMenuOptions
                  }
                />
              </Stack>
            )
          }
        >
          {children}
        </AccordionCard>
      ) : null}
    </Box>
  );
};

const DisclaimerTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: theme.shadows[8],
    padding: "12px",
  },
}));
