export const orderCardTypes = {
  businessLogistics: "bl",
  businessBuyer: "bb",
  businessHome: "bh",
  subgroupOption: "so",
  customSubgroup: "cs",
  financialPricing: "fp",
  financialFinancing: "ff",
  financialFees: "fe",
  invoiceDates: "id",
  invoiceSection: "is",
  heatUValue: "iu",
  invoiceAppliances: "ia",
  invoiceSpecifications: "ip",
  invoiceHeatingCoolingDucts: "ih",
  invoiceFloorInsulation: "if",
  invoiceExteriorWallInsulation: "iw",
  invoiceCeilingInsulation: "ic",
  salesChangeOrder: "co",
  additionalBuyerQuestions: "qa",
  addAppliance: "client-only-ap",
  addSalesChangeOrder: "client-only-co",
  retailerFees: "rf",
  constructionOption: "cc",
  constructionHiddenOption: "hc",
} as const;

export type OrderCardType =
  (typeof orderCardTypes)[keyof typeof orderCardTypes];

export type OrderCardStatus = "i" | "e" | "c";
