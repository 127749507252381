import { useEffect, useState } from "react";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import { useSession } from "../../hooks/useSession";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import InputField from "../../components/InputField";
import { TopBar } from "../../components/TopBar";
import { useApiRequest } from "../../hooks/useApiRequest";
import { generatePath, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { Prospect } from "../../types/order";
import { LookupErrorModal } from "./LookupErrorModal";

const BuyerModeRoute: React.FC = () => {
  const { user } = useSession();
  const [lookupField, setLookupField] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();
  const { data: prospectData, request: prospectRequest } =
    useApiRequest<Prospect>();
  const { request: lookupRequest } =
    useApiRequest<Prospect>();

  usePageMetadata({ title: "Buyer Mode" });

  const handleGetStartedClick = () => {
    prospectRequest("/retailer/addprospect", {
      method: "GET",
    });
  };

  // 30210001
  const handleLookupClick = () => {
    lookupRequest(`/retailer/lookup/${lookupField}`, {
      method: "GET",
    },{
      onSuccess(data: any) {
          if (!data.orderGuid) {
            setShowErrorModal(true);
          } else if (user) {
            navigate(
              generatePath(routes.quotesAndOrdersDetail.path, {
                tenantGuid: user?.tenantGuid,
                orderGuid: data.orderGuid,
              })
            );      
          }
      },
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (prospectData && user) {
      navigate(
        generatePath(routes.quotesAndOrdersDetail.path, {
          tenantGuid: user?.tenantGuid,
          orderGuid: prospectData.orderGuid,
        })
      );
    }
  });

  return (
    <>
      <TopBar buyerMode />
      <Box
        component="main"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          background: "url(/login-background.jpg) no-repeat center center",
          backgroundSize: "cover",
        }}
      >
        <Card sx={{ padding: 3, minWidth: 536 }}>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 3,
              textAlign: "center",
              mb: 0,
              fontWeight: 500,
            }}
          >
            Welcome to
          </Typography>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 3,
              textAlign: "center",
              textOverflow: "ellipsis",
              maxWidth: "65vw",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontWeight: 500,
            }}
          >
            {user?.tenantName}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleGetStartedClick}
          >
            Get Started
          </Button>
          <Divider sx={{ my: 2 }}>Or find an existing record</Divider>

          <form onSubmit={handleSubmit}>
            <InputField
              sx={{ mt: 1 }}
              placeholder="Enter Order ID or Name"
              fullWidth
              value={lookupField}
              onChange={(e) => setLookupField(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={lookupField.length === 0}
              onClick={handleLookupClick}
            >
              Look Up
            </Button>
          </form>
        </Card>
      </Box>
      {showErrorModal && (
        <LookupErrorModal onClose={() => setShowErrorModal(false)} />
      )}
    </>
  );
};

export default BuyerModeRoute;
