import React, { useCallback, useEffect, useMemo, Dispatch, SetStateAction } from "react";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Order } from "../../types/order";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {
  type OrderCardConstructionOption as OrderCardConstructionOptionType,
} from "../../types/orderCardConstructionOption";
import { OrderCardBase } from "./OrderCardBase";
import InputField from "../../components/InputField";
import SelectField, { SelectFieldProps } from "../../components/SelectField";
import {
  InputAdornment,
  Typography,
} from "@mui/material";
import { ConstructionOption } from "../../types/constructionOption";
import { useOrderCard } from "./OrderCardContext";
import { Controller, useForm } from "react-hook-form";
import { formatAsCurrency } from "../../utils/number";
import { DeepPartial } from "../../utils/deepPartial";
import { OrderCardEditResponse } from "./orderCardEditResponse";
import { OrderCard } from "../../types/orderCard";
import produce from "immer";

type FormData = {
  selectedOptionGuid: string | null;
  notes: string | null;
};

const getDefaultValues = (
  orderCard: DeepPartial<OrderCardConstructionOptionType> | null
): FormData => {
  const selectedOptionGuid = orderCard?.cardData?.selectedOptionGuid ?? "";

  return {
    selectedOptionGuid,
    notes: orderCard?.cardData?.notes ?? "",
  };
};

interface OrderCardConstructionOptionProps {
  order: Order;
  orderCard: OrderCardConstructionOptionType;
  expanded: boolean;
  groupIndex: number;
  setOrder: Dispatch<SetStateAction<Order | null>>;
}

export const OrderCardConstructionOption: React.FC<
  OrderCardConstructionOptionProps
> = ({ order, orderCard, expanded, groupIndex, setOrder }) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const {
    control,
    register,
    reset,
    formState,
    getValues,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  const optionsFieldOptions: SelectFieldProps["options"] = useMemo(() => {
    const options: SelectFieldProps["options"] = [
      ...orderCard.selectionOptions.map( (option) => getOptionOption(option) ),
    ];
    return options;
  }, [
    orderCard.selectionOptions,
  ]);

  const orderCardTotal = useMemo(() => {
    const totalPrice = orderCard.totalPrice ?? 0;
    const totalFormatted = formatAsCurrency(totalPrice);
    return totalPrice > 0 ? `+${totalFormatted}` : totalFormatted;
  }, [orderCard.totalPrice]);

  const getOrderCardData = useCallback((
    formData: FormData
  ): OrderCardConstructionOptionType["cardData"] => {
    const { ...values } = formData;

    const cardData: OrderCardConstructionOptionType["cardData"] = {
      ...values,
    };

    return cardData;
  },[]);

  // This is a subgroup option editor that doesn't nuke the rest of the card during a save event, rather it just responds
  // with a save success or failure for a single field.
  const {
    request: saveOrderCardRequest,
  } = useApiRequest<OrderCardEditResponse<OrderCard>>(false, "orderCard");


  const handleBlurField = (fieldName: string, fieldData: any) => {
    saveOrderCardRequest(`/ordercards/${orderCard.orderCardGuid}/edit/co`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { fieldName: fieldName, fieldData: fieldData },
    },{
      onSuccess: (data: any) => {
        if (order && order.orderCards) {
          setOrder((prevOrder) =>
            produce(prevOrder, (draft) => {
              if (!draft || !draft.orderCards) return;
              let index = orderCard.sortOrder;
              if (draft.orderCards.length <= index || draft.orderCards[index].orderCardGuid !== orderCard.orderCardGuid) {
                index = draft.orderCards.findIndex((dcard) => dcard.orderCardGuid === orderCard.orderCardGuid);
              }
              if (fieldName === 'notes') {
                (draft.orderCards[index] as DeepPartial<OrderCardConstructionOptionType>)
                  .cardData!.notes = fieldData;
              } else {
                (draft.orderCards[index] as DeepPartial<OrderCardConstructionOptionType>)
                  .cardData!.selectedOptionGuid = fieldData;
                (draft.orderCards[index] as DeepPartial<OrderCardConstructionOptionType>)
                  .totalPrice = data.totalPrice;
                (draft.orderCards[index] as DeepPartial<OrderCardConstructionOptionType>)
                  .orderCardStatus = data.orderCardStatus;
              }
            })
          );          
        }
      }
    });
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);

    const cardData = getOrderCardData(defaultValues);
    saveOrderCard(orderCard.orderCardGuid, cardData);
  };

  return (
    <>
      {!(orderCard?.isHidden ?? false) && (
        <OrderCardBase
          disabled={disabled}
          orderCard={orderCard}
          subtitle={orderCardTotal}
          isRequired={orderCard.isRequired}
          onClearClick={handleClearClick}
          expanded={expanded}
          groupIndex={groupIndex}
        >
          <Grid container spacing={2} component="form">
            <Grid
              xs={9}
              sm={9}
              md={9}
            >
              <Controller
                control={control}
                name="selectedOptionGuid"
                render={({ field, fieldState }) => (
                  <SelectField
                    {...field}
                    label="Options"
                    onFocus={handleEditingField}
                    placeholder="Enter Options"
                    fullWidth
                    disabled={disabled}
                    options={optionsFieldOptions}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => {
                      const newSelectedOptionGuid = e.target.value as string;
  
                      handleBlurField(field.name, newSelectedOptionGuid);  
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={3} sm={3} md={3}>
              <InputField
                value={orderCard.totalPrice}
                label="Price"
                placeholder=""
                required={false}
                fullWidth
                disabled={true}
                type="number"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                intOnly={true}
              />
            </Grid>            
            <Grid xs={12} sm={12} md={12}>
              <InputField
                {...register("notes", { 
                  onBlur: () => {
                    const cardData = getOrderCardData(getValues());
                    handleBlurField('notes', cardData.notes);
                  }
                })}
                label="Notes"
                onFocus={handleEditingField}
                placeholder="Enter Notes"
                fullWidth
                required={false}
                disabled={disabled}
                type="text"
                minRows={1}
                multiline
                error={!!formState.errors.notes}
                helperText={formState.errors.notes?.message}
              />
            </Grid>
          </Grid>
        </OrderCardBase>
      )}      
    </>
  );
};

const getOptionOption = (option: ConstructionOption, errorText?: string) => {
  return {
    label: (
      <>
        {option.optionName}
        {!!errorText ? (
          <Typography component={"span"} color="error" marginLeft={0.5}>
            {errorText}
          </Typography>
        ) : null}
      </>
    ),
    value: option.constructionOptionGuid,
  };
};
