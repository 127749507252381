import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { Button, Container, Stack } from "@mui/material";
import NewModal from "./NewQuoteModal";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Series } from "../../types/series";
import { MissingDataModal } from "./MissingDataModal";
import { PageHeader } from "../../components/PageHeader";
import FilterSearch from "./FilterSearchPanel";
import { EmptyState } from "../../components/EmptyState";
import {
  Order,
  Prospect,
  getOrderQoutePhaseMenuItems,
} from "../../types/order";
import { OrderKanbanView } from "./OrderKanbanView";
import { OrderListView } from "./OrderListView";
import { MenuItem } from "../../types/menuItem";
import { DateTime } from "luxon";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { OrderCard } from "../../types/orderCard";
import { DeleteOrderModal } from "../../components/DeleteOrderModal";
import { useSession } from "../../hooks/useSession";
import { useDetectMobile } from "../../hooks/useDetectMobile";

type OrderFilter = {
  searchText: string;
  selectedModelGuid: string | null;
  selectedRetailerGuid: string | null;
  selectedAssigneeGuid: string | null;
  selectedQuotePhase: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
};

const QuotesAndOrdersRoute: React.FC = () => {
  const isMobile = useDetectMobile();
  const navigate = useNavigate();
  const { user } = useSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const { tenantGuid } = useParams();
  const { showErrorToast } = useGlobalToastNotificationContext();
  const [isCreating, setIsCreating] = useState(false);
  const [convertingProspectGuid, setConvertingProspectGuid] =
    useState<string>();
  const [showMissingDataModal, setShowMissingDataModal] = useState(false);
  const [isGridView, setIsGridView] = useState(
    () =>
      !isMobile &&
      new URLSearchParams(window.location.search).get("view") !== "list"
  );
  const [changeOrderOrderGuid, setChangeOrderOrderGuid] = useState<string>();

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set("view", isGridView ? "kanban" : "list");
      return prev;
    });
  }, [isGridView, setSearchParams, searchParams]);

  // Filtering
  const [filters, setFilters] = useState<OrderFilter>({
    searchText: "",
    selectedModelGuid: null,
    selectedRetailerGuid: null,
    selectedAssigneeGuid: null,
    selectedQuotePhase: null,
    startDate: null,
    endDate: null,
  });

  const [deletingOrderGuid, setDeletingOrderGuid] = useState<string | null>(
    null
  );

  const { setBreadcrumbs } = useBreadcrumbs();

  usePageMetadata({ title: routes.quotesAndOrders.label });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.quotesAndOrders.label }]);
  }, [setBreadcrumbs]);

  const {
    data: seriesData,
    loading: seriesLoading,
    request: seriesRequest,
  } = useApiRequest<Series[]>();

  const { loading: newProspectLoading, request: newProspectRequest } =
    useApiRequest<Prospect>();

  const { data: orderData, request: orderRequest } =
    useApiRequest<Order[]>(false);

  const {
    data: addChangeOrderData,
    request: addChangeOrderRequest,
    loading: addChangeOrderLoading,
    status: addChangeOrderStatus,
    errorMessage: addChangeOrderErrorMessage,
  } = useApiRequest<OrderCard>(false);

  const refreshOrders = useCallback(() => {
    orderRequest("/orders", { method: "GET" });
  }, [orderRequest]);

  const modelOptions: MenuItem[] = useMemo(() => {
    if (!orderData) {
      return [];
    }

    // Dedupe models
    const uniqueOrderModels: MenuItem[] = orderData.reduce(
      (acc: MenuItem[], order) => {
        if (
          !acc.find((item: MenuItem) => item.value === order.modelGuid)
        ) {
          acc.push({
            label: order?.modelNumber ?? "",
            value: order?.modelGuid ?? "",
          });
        }
        return acc;
      },
      []
    );

    return uniqueOrderModels;
  }, [orderData]);

  const retailerOptions: MenuItem[] = useMemo(() => {
    if (!orderData) {
      return [];
    }

    // Dedupe retailers
    const uniqueRetailers: MenuItem[] = orderData.reduce(
      (acc: MenuItem[], order) => {
        if (
          order.retTenantName &&
          !acc.find(
            (item: MenuItem) => item.value === order.retailerGuid
          )
        ) {
          acc.push({
            label: order.retTenantName,
            value: order.retailerGuid ?? "",
          });
        }
        return acc;
      },
      []
    );

    return uniqueRetailers;
  }, [orderData]);

  const quotePhaseOptions: MenuItem[] = useMemo(() => {
    return getOrderQoutePhaseMenuItems();
  }, []);

  const filteredOrders = useMemo(() => {
    if (!orderData) {
      return [];
    }
    return orderData.filter((order) => {
      const st = filters.searchText.toLowerCase();
      const dateFormat = "yyyy/MM/dd";
      const textFilter =
        order.orderIdentifier.toLowerCase().includes(st) ||
        order.basePlusOptions?.toString().includes(st) ||
        order.calculatedVinNumber?.toLowerCase().includes(st) ||
        order.templateName?.toLowerCase().includes(st) ||
        order.invoiceNumber?.toString().includes(st) ||
        order.modelNumber?.toLowerCase().includes(st) ||
        order.retTenantName?.toLowerCase().includes(st) ||
        order.assigneeUserFullName?.toLowerCase().includes(st) ||
        order.buyerLastName?.toLowerCase().includes(st) ||
        order.createdByText?.toLowerCase().includes(st) ||
        order.mfgTenantName?.toLowerCase().includes(st)
        ;

      const modelFilter =
        !filters.selectedModelGuid ||
        order.model.modelGuid === filters.selectedModelGuid;
      const retailerFilter =
        !filters.selectedRetailerGuid ||
        order?.retailerGuid === filters.selectedRetailerGuid;
      const assigneeFilter =
        !filters.selectedAssigneeGuid ||
        order?.assigneeUserGuid === filters.selectedAssigneeGuid;
      const startDateFilter =
        !filters.startDate ||
        DateTime.fromSeconds(order?.createdTimestamp).toFormat(dateFormat) >=
          filters.startDate.toFormat(dateFormat);

      const endDateFilter =
        !filters.endDate ||
        DateTime.fromSeconds(order?.createdTimestamp).toFormat(dateFormat) <=
          filters.endDate.toFormat(dateFormat);
      const quotePhaseFilter =
        !filters.selectedQuotePhase ||
        (order?.quotePhase === filters.selectedQuotePhase && order?.orderStatus === "q");

      return (
        modelFilter &&
        retailerFilter &&
        assigneeFilter &&
        startDateFilter &&
        endDateFilter &&
        quotePhaseFilter &&
        textFilter
      );
    });
  }, [orderData, filters]);

  const assigneeOptions = useMemo(() => {
    if (!orderData) {
      return [];
    }

    // Dedupe assignees
    const uniqueAssignees: MenuItem[] = orderData.reduce(
      (acc: MenuItem[], order) => {
        if (
          order.assigneeUserFullName &&
          !acc.find(
            (item: MenuItem) => item.value === order.assigneeUserGuid
          )
        ) {
          acc.push({
            label: `${order.assigneeUserFullName}`,
            value: order.assigneeUserGuid,
          });
        }
        return acc;
      },
      []
    );

    return uniqueAssignees;
  }, [orderData]);

  const deletingOrder = useMemo(() => {
    return orderData?.find((o) => o.orderGuid === deletingOrderGuid);
  }, [deletingOrderGuid, orderData]);

  useEffect(() => {
    refreshOrders();
  }, [refreshOrders]);

  const hasSeries = useMemo(() => {
    if (user?.isRetailUser) {
      return true;
    }
    if (!seriesData || seriesData.length === 0) {
      return false;
    }
    return seriesData.some((series) => series?.models.length > 0);
  }, [seriesData, user?.isRetailUser]);

  useEffect(() => {
    if (seriesData) {
      if (hasSeries) {
        setIsCreating(true);
      } else {
        setShowMissingDataModal(true);
      }
    }
  }, [seriesData, hasSeries]);

  useEffect(() => {
    if (
      addChangeOrderData &&
      addChangeOrderStatus === "ok" &&
      addChangeOrderLoading === false &&
      changeOrderOrderGuid
    ) {
      navigate(
        generatePath(routes.quotesAndOrdersDetail.path, {
          tenantGuid: tenantGuid ?? "",
          orderGuid: changeOrderOrderGuid,
        }),
        { state: { jumpToCardGuid: addChangeOrderData.orderCardGuid } }
      );
    }
  }, [
    addChangeOrderData,
    addChangeOrderLoading,
    addChangeOrderStatus,
    changeOrderOrderGuid,
    navigate,
    tenantGuid,
  ]);

  useEffect(() => {
    if (addChangeOrderErrorMessage) {
      showErrorToast({ message: addChangeOrderErrorMessage });
    }
  }, [addChangeOrderErrorMessage, showErrorToast]);

  const handleNewQuoteClick = useCallback(() => {
    seriesRequest("/quotes/series", {
      method: "GET",
    });
  }, [seriesRequest]);

  const handleNewProspectClick = useCallback(() => {
    newProspectRequest(
      "/retailer/addprospect",
      {
        method: "GET",
      },
      {
        onSuccess: (data) => {
          if (!user?.tenantGuid) {
            return;
          }
          navigate(
            generatePath(routes.quotesAndOrdersDetail.path, {
              tenantGuid: user?.tenantGuid,
              orderGuid: data.orderGuid,
            })
          );
        },
      }
    );
  }, [newProspectRequest, navigate, user?.tenantGuid]);

  const handleSearchTextChange = useCallback((searchText: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchText,
    }));
  }, []);

  const handleAssigneeSelection = useCallback((assigneeGuid: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedAssigneeGuid: assigneeGuid,
    }));
  }, []);

  const handleModelSelection = useCallback((modelGuid: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedModelGuid: modelGuid,
    }));
  }, []);

  const handleRetailerSelection = useCallback((retailerGuid: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedRetailerGuid: retailerGuid,
    }));
  }, []);

  const handleQuotePhaseSelection = useCallback((quotePhase: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedQuotePhase: quotePhase,
    }));
  }, []);

  const handleStartDateSelection = useCallback((startDate: DateTime | null) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate,
    }));
  }, []);

  const handleEndDateSelection = useCallback((endDate: DateTime | null) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      endDate,
    }));
  }, []);

  const handleOrderEditClick = useCallback(
    (orderGuid: string) => {
      navigate(
        `${generatePath(routes.quotesAndOrdersDetail.path, {
          tenantGuid: tenantGuid ?? "",
          orderGuid,
        })}?backTo=${isGridView ? "kanban" : "list"}`
      );
    },
    [navigate, tenantGuid, isGridView]
  );

  const handleNewChangeOrderClick = useCallback(
    (orderGuid: string) => {
      setChangeOrderOrderGuid(orderGuid);
      addChangeOrderRequest(`/orders/${orderGuid}/changeorders/add`, {
        method: "GET",
      });
    },
    [addChangeOrderRequest]
  );

  const handleDeleteOrderClick = (orderGuid: string) => {
    setDeletingOrderGuid(orderGuid);
  };

  const handleDeleteOrderClose = () => {
    setDeletingOrderGuid(null);
  };

  const handleDeleteOrderSuccess = () => {
    handleDeleteOrderClose();
    refreshOrders();
  };

  const handleConvertToQuoteClick = (prospectGuid: Prospect["orderGuid"]) => {
    setIsCreating(true);
    setConvertingProspectGuid(prospectGuid);
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageHeader
          title="Quotes and Orders"
          actions={
            <Stack direction="row" spacing={1}>
              {user?.isRetailUser && (
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={seriesLoading || newProspectLoading}
                  onClick={handleNewProspectClick}
                  sx={{
                    color: "primary.main",
                    backgroundColor: "primary.light",
                    ":hover": { backgroundColor: "secondary.main" },
                    border: "none",
                  }}
                >
                  + New Prospect
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={seriesLoading || newProspectLoading}
                onClick={handleNewQuoteClick}
              >
                + New Quote
              </Button>
            </Stack>
          }
        />
        <FilterSearch
          isGridView={isGridView}
          onViewChange={(isGridView) => setIsGridView(isGridView)}
          onSearchTextChange={handleSearchTextChange}
          // Model
          modelOptions={modelOptions}
          onModelSelect={handleModelSelection}
          // Retailer
          retailerOptions={retailerOptions}
          onRetailerSelect={handleRetailerSelection}
          // Assignee
          assigneeOptions={assigneeOptions}
          onAssigneeSelect={handleAssigneeSelection}
          // Date
          onStartDateSelect={handleStartDateSelection}
          onEndDateSelect={handleEndDateSelection}
          // Quote Phase
          quotePhaseOptions={quotePhaseOptions}
          onQuotePhaseSelect={handleQuotePhaseSelection}
        />
      </Container>
      {isCreating && (
        <NewModal
          isOpen={isCreating}
          onClose={() => setIsCreating(false)}
          prospectGuid={convertingProspectGuid}
          onSaveSuccessful={(order) => {
            setIsCreating(false);
            navigate(
              generatePath(routes.quotesAndOrdersDetail.path, {
                tenantGuid: tenantGuid ?? "",
                orderGuid: order.orderGuid,
              })
            );
          }}
        />
      )}
      {showMissingDataModal && (
        <MissingDataModal onClose={() => setShowMissingDataModal(false)} />
      )}
      {orderData && orderData.length === 0 ? (
        <EmptyState
          heading="No Quotes or Orders"
          body="To create a quote, click the New Quote Button"
        />
      ) : isGridView ? (
        <OrderKanbanView
          orders={filteredOrders || []}
          refreshOrders={refreshOrders}
          handleOrderEditClick={handleOrderEditClick}
          handleNewChangeOrderClick={handleNewChangeOrderClick}
          handleDeleteOrderClick={handleDeleteOrderClick}
          handleConvertToQuoteClick={handleConvertToQuoteClick}
        />
      ) : (
        <OrderListView
          orders={filteredOrders || []}
          handleOrderEditClick={handleOrderEditClick}
          handleNewChangeOrderClick={handleNewChangeOrderClick}
          handleDeleteOrderClick={handleDeleteOrderClick}
        />
      )}
      {deletingOrder && (
        <DeleteOrderModal
          order={deletingOrder}
          onDeleteSuccess={handleDeleteOrderSuccess}
          onClose={handleDeleteOrderClose}
        />
      )}
    </>
  );
};

export default QuotesAndOrdersRoute;
