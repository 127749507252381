import React from "react";
import ReactDOM from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { redRootsTechTheme } from "./theme";
import { BreadcrumbsProvider } from "./context/BreadcrumbsContext";
import { SessionProvider } from "./context/SessionContext";
import { GlobalToastNotificationContextProvider } from "./context/GlobalToastNotificationContext";
import GlobalToastNotification from "./components/GlobalToastNotification";
import { router } from "./router";

const theme = createTheme(redRootsTechTheme);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  //<React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme}>
        <SessionProvider>
          <BreadcrumbsProvider>
            <GlobalToastNotificationContextProvider>
              <GlobalToastNotification />
              <RouterProvider router={router} />
            </GlobalToastNotificationContextProvider>
          </BreadcrumbsProvider>
        </SessionProvider>
      </ThemeProvider>
    </LocalizationProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
