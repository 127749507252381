export type ConstructionOptionPricing = {
  type: "a" | "sw" | "dw" | "tw" | "m";
  price: number;
  min: number | undefined;
  max: number | undefined;
};

export type ConstructionOptionPricingType = ConstructionOptionPricing["type"];

export const getConstructionOptionPricingDisplayName = (
  unit: ConstructionOptionPricingType
): string => {
  const displayName: Record<ConstructionOptionPricingType, string> = {
    a: "Pricing",
    sw: "Single Wide Price",
    dw: "Double Wide Price",
    tw: "Triple Wide Price",
    m: "MOD Price",
  };
  return displayName[unit];
};
