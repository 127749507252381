import { Attribute } from "./attribute";
import { AttributeSelection } from "./attributeSelection";
import { Group } from "./group";
import {
  applyLogicToAttributeSelections,
  applyLogicToOptions,
} from "./logicResult";
import { Option } from "./option";
import { OptionRoom } from "./optionRoom";
import { Order } from "./order";
import { OrderCardError } from "./orderCard";
import { orderCardTypes, OrderCardStatus } from "./orderCardTypes";
import { Subgroup } from "./subgroup";

export type OrderCardSOInvalidGroupError = OrderCardError<{ group: Group }>;
export type OrderCardSOInvalidSubgroupError = OrderCardError<{
  subgroup: Subgroup;
}>;
export type OrderCardSOInvalidOption = OrderCardError<{ option: Option }>;
export type OrderCardSOAttributeRemoved = OrderCardError<{
  attribute: Attribute;
}>;
export type OrderCardSOAttributeExpired = OrderCardError<{
  attributeselection: AttributeSelection;
}>;
export type OrderCardSOInvalidRooms = OrderCardError<{
  invalidRooms: OptionRoom[];
}>;
export type OrderCardSubgroupOptionInvalidSeriesError = OrderCardError<{
  option: Option;
}>;
export type OrderCardSOMinQuantity = OrderCardError<number>;
export type OrderCardSOMaxQuantity = OrderCardError<number>;

export type OrderCardSubgroupOption = {
  orderCardGuid: string;
  orderCardType: (typeof orderCardTypes)["subgroupOption"];
  categoryName: string;
  groupName: string;
  subgroupName: string;
  isRequired: boolean;
  isApproved: boolean;
  isDeletable: boolean;
  isHidden: boolean;
  isLocked: boolean;
  orderCardStatus: OrderCardStatus;
  sortOrder: number;
  customOptionAllowed: boolean;
  selectionOptions: Option[];
  groupGuid: string | null;
  subgroupGuid: string;
  errors: OrderCardError[];
  rooms: OptionRoom[] | null;
  colors: string[] | null;
  totalPrice: number | null;
  cardData: {
    customOptionSelected: boolean;
    color: string | null;
    price: number | null;
    selectedOptionGuid: string | null;
    quantity: number | null | undefined;
    selectedRooms: OptionRoom[];
    roomsAndQuantities: {
      roomName: OptionRoom;
      quantity: number;
    }[];
    attributeSelections: {
      attributeGuid: string;
      attributeselectionGuid: string;
    }[];
    notes: string | null;
  };
};

export const getOrderCardSubgroupColorOptions = (
  orderCard: OrderCardSubgroupOption
) => {
  return (orderCard.colors ?? []).map((color) => ({
    label: color,
    value: color,
  }));
};

export const orderCardSubgroupOptionHasLogicErrors = (
  order: Order,
  orderCard: OrderCardSubgroupOption
): boolean => {
  const logicAffected = order.logicAffected;
  if (logicAffected == null) {
    return false;
  }

  if (orderCard.cardData.customOptionSelected) {
    return false;
  }

  if (!orderCard.cardData.selectedOptionGuid) {
    return false;
  }

  const selectedOption = orderCard.selectionOptions.find(
    (option) => option.optionGuid === orderCard.cardData.selectedOptionGuid
  );
  if (!selectedOption) {
    return false;
  }

  const optionLogicResult = applyLogicToOptions(
    orderCard.selectionOptions,
    orderCard.cardData.selectedOptionGuid,
    logicAffected
  );
  if (optionLogicResult.entitiesWithLogicErrors.length > 0) {
    return true;
  }

  const selectedAttributeSelectionGuids =
    orderCard.cardData.attributeSelections.map(
      ({ attributeselectionGuid }) => attributeselectionGuid
    );

  const hasAttributeWithLogicError = selectedOption.attributes.some(
    (attribute) => {
      const attributeSelectionLogicResult = applyLogicToAttributeSelections(
        attribute.attributeselections,
        selectedAttributeSelectionGuids,
        logicAffected,
        selectedOption.optionGuid
      );
      return attributeSelectionLogicResult.entitiesWithLogicErrors.length > 0;
    }
  );

  return hasAttributeWithLogicError;
};
