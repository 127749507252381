import SelectField from "../../components/SelectField";
import {
  AttributeSelection,
  getAttributeSelectionImageUrl,
} from "../../types/attributeSelection";
import { formatAsCurrency } from "../../utils/number";
import React, { useMemo, useState, useEffect, useCallback, Dispatch, SetStateAction } from "react";
import { Attribute } from "../../types/attribute";
import SwatchField, { SwatchFieldOption } from "../../components/SwatchField";
import InputField from "../../components/InputField";
import { Box, Stack, Typography } from "@mui/material";
import { OrderCardError, getOrderCardError } from "../../types/orderCard";
import { 
  OrderCardSubgroupOption,
  type OrderCardSubgroupOption as OrderCardSubgroupOptionType
} from "../../types/orderCardSubgroupOption";
import { formatAsDate } from "../../utils/date";
import { LogicAffected } from "../../types/logicAffected";
import { applyLogicToAttributeSelections } from "../../types/logicResult";
import { Option } from "../../types/option";
import { Logic } from "../../types/logic";
import { Order } from "../../types/order";
import { DeepPartial } from "../../utils/deepPartial";
import produce from "immer";

interface OrderCardSubgroupOptionAttributeFieldProps {
  orderCard: OrderCardSubgroupOption;
  attribute: Attribute;
  errors: OrderCardError[];
  name: string;
  disabled: boolean;
  value: string;
  logicAffected: LogicAffected | null;
  onChange: (value: string) => void;
  onBlur: () => void;
  selectedOption?: Option;
  unhideOrUnRequireOtherCardsWithLogic: (logics: Logic[]) => void;
  unhideOrUnRequireOtherCardOptionsWithLogic: (logics: Logic[]) => void;
  hideOrRequireOtherCardsWithLogic: (logics: Logic[]) => void;
  hideOrRequireOtherCardOptionsWithLogic: (logics: Logic[]) => void;
  order: Order;
  setOrder: Dispatch<SetStateAction<Order | null>>;
}

export const OrderCardSubgroupOptionAttributeField = React.forwardRef<
  HTMLInputElement,
  OrderCardSubgroupOptionAttributeFieldProps
>(
  (
    {
      orderCard,
      attribute,
      disabled,
      value,
      name,
      logicAffected,
      onChange,
      onBlur,
      selectedOption,
      unhideOrUnRequireOtherCardsWithLogic,
      unhideOrUnRequireOtherCardOptionsWithLogic,
      hideOrRequireOtherCardsWithLogic,
      hideOrRequireOtherCardOptionsWithLogic,
      order,
      setOrder,
    },
    ref
  ) => {
    const isAttributeSelectionRemoved = useMemo(() => {
      const errorData = getOrderCardError(orderCard, "esoar");
      return errorData?.attribute.attributeGuid === attribute.attributeGuid;
    }, [attribute.attributeGuid, orderCard]);

    const attributeSelectionOptions: SwatchFieldOption<string>[] =
      useMemo(() => {
        if (attribute.attributeselections.length === 0) {
          return [];
        }

        const invalidAttributeSelection = getOrderCardError(
          orderCard,
          "esoae"
        )?.attributeselection;

        const invalidSelectionOption = invalidAttributeSelection
          ? [
              getAttributeSelectionOption(
                attribute,
                invalidAttributeSelection,
                false,
                `Exp ${formatAsDate(invalidAttributeSelection?.endDate)}`
              ),
            ]
          : [];

        const selectedAttributeSelectionGuids =
          orderCard.cardData.attributeSelections.map(
            ({ attributeselectionGuid }) => attributeselectionGuid
          );

        // This handles global logic
        const logicResult = applyLogicToAttributeSelections(
          attribute.attributeselections,
          selectedAttributeSelectionGuids,
          logicAffected,
          selectedOption?.optionGuid
        );

        const selectionOptions: SwatchFieldOption<string>[] = [
          ...invalidSelectionOption,
          ...logicResult.entitiesWithLogicErrors.map((attributeSelection) => getAttributeSelectionOption(attribute, attributeSelection, true)),
          ...logicResult.entitiesWithoutLogicErrors
            // This handles local hide when ifType == 'o'
            .filter((attributeSelection) => {
              if (!selectedOption) return true;

              // This catches the remote hide or show thisType == 'a'
              if (attributeSelection.isHidden === true) return false;

              // Since this is a local check, we only care if there are any local logical hides
              return !(selectedOption.logics.some((logic) => logic.thenRequireHide === 'h' && logic.ifType === 'o' && logic.ifOptionGuid === selectedOption.optionGuid && (logic.thisTargets ?? []).some((t) => t.thisType === 'a' && t.thisOptionGuid === selectedOption.optionGuid && t.thisAttributeselectionGuid === attributeSelection.attributeselectionGuid)));
            })
            .map((attributeSelection) => getAttributeSelectionOption(attribute, attributeSelection, false)),
            // This handles local require when if type == 'o'
          ...attribute.attributeselections
            .filter((attributeSelection) => {
              if (!selectedOption) return true;
              if ([...logicResult.entitiesWithLogicErrors, ...logicResult.entitiesWithoutLogicErrors].some((ats) => ats.attributeselectionGuid === attributeSelection.attributeselectionGuid)) return false;
              if (attributeSelection.isHidden === true) return false;
              if (selectedOption.logics.length === 0) return true;

              // Since this is a local check, we only care if there are any local logical hides
              const areThereSome = (selectedOption.logics.some((logic) => logic.thenRequireHide === 'r' && logic.ifType === 'o' && logic.ifOptionGuid === selectedOption.optionGuid && (logic.thisTargets ?? []).some((t) => t.thisType === 'a' && t.thisOptionGuid === selectedOption.optionGuid && attribute.attributeselections.some((ats) => t.thisAttributeselectionGuid === ats.attributeselectionGuid))));
              if (!areThereSome) return true;

              return (selectedOption.logics.some((logic) => logic.thenRequireHide === 'r' && logic.ifType === 'o' && logic.ifOptionGuid === selectedOption.optionGuid && (logic.thisTargets ?? []).some((t) => t.thisType === 'a' && t.thisOptionGuid === selectedOption.optionGuid && t.thisAttributeselectionGuid === attributeSelection.attributeselectionGuid)));
            })
            .map((attributeSelection) => getAttributeSelectionOption(attribute, attributeSelection, false),
          ),
        ];

        return selectionOptions;
      }, [attribute, logicAffected, orderCard, selectedOption]);

    const selectedSwatchOption = useMemo(
      () => attributeSelectionOptions.find((a) => a.value === value),
      [attributeSelectionOptions, value]
    );

    // ============================ LOGIC ==========================
    const remoteSubgroupAndOptionsAndAttributeselectionsLogicAffectedBySelectionAndAttribute = useCallback((guid: string): [Logic[], Logic[], Logic[]] => {
      let subgroupsLogic: Logic[] = [];
      let optionsLogic: Logic[] = [];
      let atsLogic: Logic[] = [];
      if (!selectedOption) return [subgroupsLogic, optionsLogic, atsLogic];
      selectedOption.logics.forEach((l) => {
        if (l.ifOptionGuid === selectedOption.optionGuid && l.ifType === 'a' && l.ifAttributeselectionGuid === guid) {
          if ((l.thisTargets ?? []).some((t) => t.thisType === 's')) {
            subgroupsLogic.push(l);
          } else if ((l.thisTargets ?? []).some((t) => t.thisType === 'o' && t.thisOptionGuid !== selectedOption.optionGuid)) {
            optionsLogic.push(l);
          } else if ((l.thisTargets ?? []).some((t) => t.thisType === 'a' && t.thisOptionGuid !== selectedOption.optionGuid)) {
            atsLogic.push(l);
          }
        }
      });
      return [subgroupsLogic, optionsLogic, atsLogic];
    },[selectedOption]);

    const hideOrRequireOtherCardAttributeselectionsWithLogic = useCallback((logics: Logic[]) => {
      if (logics.length === 0) return;
      if (order?.orderCards && orderCard?.orderCardGuid) {
        for(let i=0; i<order.orderCards.length; i++) {
          if (order.orderCards[i].orderCardType === 'so' && order.orderCards[i].orderCardGuid !== orderCard.orderCardGuid) {
            for(let j=0; j<logics.length; j++) {
              if (logics[j].ifAttributeselectionGuid === value) {
                for(let k=0; k<(logics[j].thisTargets ?? []).length; k++) {
                  for(let g=0; g<(order.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions!.length; g++) {
                    if (logics[j].thisTargets[k].thisType === 'a' && logics[j].thisTargets[k].thisOptionGuid === (order.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].optionGuid) {
                      for(let h=0; h<(order.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes.length; h++) {
                        for(let f=0; f<(order.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes[h].attributeselections.length; f++) {
                          if ((order.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes[h].attributeselections[f].attributeselectionGuid === logics[j].thisTargets[k].thisAttributeselectionGuid) {
                            setOrder((prevOrder) =>
                              produce(prevOrder, (draft) => {
                                if (!draft || !draft.orderCards) return;
                                if (logics[j].thenRequireHide === 'h') {
                                  (draft.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes[h].attributeselections[f].isHidden = true;
                                } else {
                                  (draft.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes[h].attributeselections[f].isHidden = false;
                                  (draft.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes[h].attributeSelectIsRequired = true;
                                }
                              })
                            );
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },[order?.orderCards, orderCard?.orderCardGuid, setOrder, value]);

    const unhideOrUnRequireOtherCardAttributeselectionsWithLogic = useCallback((logics: Logic[]) => {
      if (logics.length === 0) return;
      if (order?.orderCards && orderCard?.orderCardGuid) {
        for(let i=0; i<order.orderCards.length; i++) {
          if (order.orderCards[i].orderCardType === 'so' && order.orderCards[i].orderCardGuid !== orderCard.orderCardGuid) {
            for(let j=0; j<logics.length; j++) {
              for(let k=0; k<(logics[j].thisTargets ?? []).length; k++) {
                for(let g=0; g<(order.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions!.length; g++) {
                  if (logics[j].thisTargets[k].thisType === 'a' && logics[j].thisTargets[k].thisOptionGuid === (order.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].optionGuid) {
                    for(let h=0; h<(order.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes.length; h++) {
                      for(let f=0; f<(order.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes[h].attributeselections.length; f++) {
                        if ((order.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes[h].attributeselections[f].attributeselectionGuid === logics[j].thisTargets[k].thisAttributeselectionGuid) {
                          setOrder((prevOrder) =>
                            produce(prevOrder, (draft) => {
                              if (!draft || !draft.orderCards) return;
                              if (logics[j].thenRequireHide === 'h') {
                                (draft.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes[h].attributeselections[f].isHidden = false;
                              } else {
                                (draft.orderCards[i] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![g].attributes[h].attributeSelectIsRequired = false;
                              }
                            })
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },[order?.orderCards, orderCard?.orderCardGuid, setOrder]);

    const localHideOrRequire = useCallback(() => {
      if (selectedOption && order && order.orderCards) {
        let index = orderCard.sortOrder;
        if (order.orderCards.length <= index || order.orderCards[index].orderCardGuid !== orderCard.orderCardGuid) {
          index = order.orderCards.findIndex((dcard) => dcard.orderCardGuid === orderCard.orderCardGuid);
        }
        selectedOption.logics.forEach((logic) => {
          if (logic.ifType === 'a' && logic.thenRequireHide === 'h' && logic.ifOptionGuid === selectedOption.optionGuid && logic.ifAttributeselectionGuid === value) {
            const hideAttributeSelectionGuids = (logic.thisTargets ?? []).filter((t) => t.thisType === 'a' && t.thisOptionGuid === selectedOption.optionGuid && t.thisAttributeselectionGuid !== value).map((t) => t.thisAttributeselectionGuid);
            if (hideAttributeSelectionGuids.length > 0) {

              for(let j=0; j<(order.orderCards![index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions!.length; j++) {
                for(let k=0; k<(order.orderCards![index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes.length; k++) {
                  if (k === attribute.sortOrder) continue;

                  for(let g=0; g<(order.orderCards![index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes[k].attributeselections.length; g++) {
                    if (hideAttributeSelectionGuids.some((guid) => guid === (order.orderCards![index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes[k].attributeselections[g].attributeselectionGuid)) {
                      setOrder((prevOrder) =>
                        produce(prevOrder, (draft) => {
                          if (!draft || !draft.orderCards) return;
  
                          if (logic.thenRequireHide === 'h') {
                            (draft.orderCards[index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes[k].attributeselections[g].isHidden = true;
                          } else {
                            (draft.orderCards[index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes[k].attributeselections[g].isHidden = false;
                            (draft.orderCards[index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes[k].attributeSelectIsRequired = true;
                          }
                        })
                      );        
                    }
                  }
                }
              }
            }              
          }
        });
      }
    },[
      attribute.sortOrder,
      selectedOption,
      setOrder,
      order,
      orderCard,
      value,
    ]);

    // Check if the currently selected option + attribute needs to affect other subgroup option cards
    const [prevSelectedAttributeselectionGuid, setPrevSelectedAttributeselectionGuid] = useState<string | null | undefined>(value);

    const localUnHideOrUnRequire = useCallback(() => {
      if (selectedOption && order && order.orderCards) {
        let index = orderCard.sortOrder;
        if (order.orderCards.length <= index || order.orderCards[index].orderCardGuid !== orderCard.orderCardGuid) {
          index = order.orderCards.findIndex((dcard) => dcard.orderCardGuid === orderCard.orderCardGuid);
        }    
        selectedOption.logics.forEach((logic) => {
          if (logic.ifType === 'a' && logic.thenRequireHide === 'h' && logic.ifOptionGuid === selectedOption.optionGuid && logic.ifAttributeselectionGuid === prevSelectedAttributeselectionGuid) {
            const hideAttributeSelectionGuids = (logic.thisTargets ?? []).filter((t) => t.thisType === 'a' && t.thisOptionGuid === selectedOption.optionGuid && t.thisAttributeselectionGuid !== prevSelectedAttributeselectionGuid).map((t) => t.thisAttributeselectionGuid);
            if (hideAttributeSelectionGuids.length > 0) {

              for(let j=0; j<(order.orderCards![index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions!.length; j++) {
                for(let k=0; k<(order.orderCards![index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes.length; k++) {
                  for(let g=0; g<(order.orderCards![index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes[k].attributeselections.length; g++) {
                    if (hideAttributeSelectionGuids.some((guid) => guid === (order.orderCards![index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes[k].attributeselections[g].attributeselectionGuid)) {
                      setOrder((prevOrder) =>
                        produce(prevOrder, (draft) => {
                          if (!draft || !draft.orderCards) return;
                          if (logic.thenRequireHide === 'h') {
                            (draft.orderCards[index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes[k].attributeselections[g].isHidden = false;
                          } else {
                            (draft.orderCards[index] as DeepPartial<OrderCardSubgroupOptionType>).selectionOptions![j].attributes[k].attributeSelectIsRequired = false;
                          }
                        })
                      );        
                    }
                  }
                }
              }
            }              
          }
        });
      }
    },[
      selectedOption,
      setOrder,
      order,
      orderCard,
      prevSelectedAttributeselectionGuid,
    ]);

    useEffect(() => {
      if (!attribute) return;
      if (prevSelectedAttributeselectionGuid && prevSelectedAttributeselectionGuid !== value) {
        // Need to clear logic from the previous selection
        const [prevSubgroupsLogics, prevOptionsLogics, prevAtsLogic] = remoteSubgroupAndOptionsAndAttributeselectionsLogicAffectedBySelectionAndAttribute(prevSelectedAttributeselectionGuid);
        unhideOrUnRequireOtherCardsWithLogic(prevSubgroupsLogics);
        unhideOrUnRequireOtherCardOptionsWithLogic(prevOptionsLogics);
        unhideOrUnRequireOtherCardAttributeselectionsWithLogic(prevAtsLogic);

        // Local unhide based on previously selected attribute
        localUnHideOrUnRequire();
      }
      if (prevSelectedAttributeselectionGuid !== value) {
        setPrevSelectedAttributeselectionGuid(value);
      }
      if (value) {
        // Set current logic
        const [thisSubgroupsLogics, thisOptionsLogics, thisAtsLogic] = remoteSubgroupAndOptionsAndAttributeselectionsLogicAffectedBySelectionAndAttribute(value)
        hideOrRequireOtherCardsWithLogic(thisSubgroupsLogics);
        hideOrRequireOtherCardOptionsWithLogic(thisOptionsLogics);
        hideOrRequireOtherCardAttributeselectionsWithLogic(thisAtsLogic);

        // Local hide or require based on selected attribute
        localHideOrRequire();
      }
    },
    [
      attribute,
      value,
      prevSelectedAttributeselectionGuid,
      setPrevSelectedAttributeselectionGuid,
      hideOrRequireOtherCardsWithLogic,
      hideOrRequireOtherCardOptionsWithLogic,
      unhideOrUnRequireOtherCardsWithLogic,
      unhideOrUnRequireOtherCardOptionsWithLogic,
      remoteSubgroupAndOptionsAndAttributeselectionsLogicAffectedBySelectionAndAttribute,
      unhideOrUnRequireOtherCardAttributeselectionsWithLogic,
      hideOrRequireOtherCardAttributeselectionsWithLogic,
      localHideOrRequire,
      localUnHideOrUnRequire,
    ]
    );

    return attribute.attributePresentAs === "s" ? (
      <SwatchField
        ref={ref}
        label={attribute.attributeName}
        exclusive={true}
        required={attribute.attributeSelectIsRequired}
        error={isAttributeSelectionRemoved}
        helperText={isAttributeSelectionRemoved ? "Removed" : undefined}
        options={attributeSelectionOptions}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    ) : attribute.attributePresentAs === "d" ? (
      <Stack direction="row" spacing={2}>
        <SelectField
          ref={ref}
          label={attribute.attributeName}
          placeholder={`Enter ${attribute.attributeName}`}
          fullWidth
          required={attribute.attributeSelectIsRequired}
          error={isAttributeSelectionRemoved}
          labelHelperText={isAttributeSelectionRemoved ? "Removed" : undefined}
          disabled={disabled}
          options={attributeSelectionOptions}
          value={value}
          onChange={(e) => {
            onChange(e.target.value as string);
          }}
          onBlur={onBlur}
          name={name}
        />
        <Box width="20%" minWidth="90px" flexShrink={0}>
          <InputField
            label="Price"
            fullWidth
            disabled
            value={selectedSwatchOption?.labelSecondary ?? ""}
          />
        </Box>
      </Stack>
    ) : null;
  }
);

const getAttributeSelectionOption = (
  attribute: Attribute,
  attributeSelection: AttributeSelection,
  hasLogicError: boolean,
  error?: string
): SwatchFieldOption<string> => {
  return {
    label: (
      <>
        {attributeSelection.attributeselectionName}
        {hasLogicError ? (
          <Typography
            component={"span"}
            color="error"
            marginLeft={0.5}
            fontSize="inherit"
          >
            (Logic Error)
          </Typography>
        ) : null}
      </>
    ),
    labelSecondary: formatAsCurrency(
      attributeSelection.attributeselectionPrice
    ),
    value: attributeSelection.attributeselectionGuid,
    imageUrl: attribute.attributeIncludeImages
      ? getAttributeSelectionImageUrl(attributeSelection)
      : undefined,
    error: !!error,
    helperText: error,
  };
};
