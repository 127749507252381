export type FormConstructionOptionPricing = {
  type: "a" | "sw" | "dw" | "tw" | "m";
  price: number | null;
  min: number | null | undefined;
  max: number| null | undefined;
};

export const FormConstructionOptionPricingDefaults: FormConstructionOptionPricing[] = [
  {
    type: "a",
    price: null,
    min: null,
    max: null,
  },
  {
    type: "sw",
    price: null,
    min: null,
    max: null,
  },
  {
    type: "dw",
    price: null,
    min: null,
    max: null,
  },
  {
    type: "tw",
    price: null,
    min: null,
    max: null,
  },
  {
    type: "m",
    price: null,
    min: null,
    max: null,
  },
];

export type FormConstructionData = {
  optionName: string;
  optionPricedPer: "" | "u" | "lf" | "plf" | "sf";
  optionPricedFor: "a" | "u" | null;
  optionPricing: FormConstructionOptionPricing[];
};
