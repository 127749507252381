import React, { useEffect } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  useFieldArray,
} from "react-hook-form";
import { FormSection } from "../../components/FormSection";
import { Grid, InputAdornment, IconButton } from "@mui/material";
import { Delete, AddCircleOutlineOutlined } from "@mui/icons-material";
import InputField from "../../components/InputField";
import { FormConstructionData, FormConstructionOptionPricingDefaults } from "./FormConstructionData";
import SelectField from "../../components/SelectField";
import {
  getConstructionOptionPricePerDisplayName,
  getConstructionOptionPricePerShortDisplayName,
  getConstructionOptionPriceForDisplayName,
  getPriceForConstructionOptions,
  getPricePerConstructionOptions,
  ConstructionOptionPricedPerType,
} from "../../types/constructionOption";
import { getConstructionOptionPricingDisplayName } from "../../types/constructionOptionPricing";
import { isValidCurrency } from "../../utils/validation";

interface ConstructionOptionPricingFormSectionProps {
  errors: FieldErrors<FormConstructionData>;
  register: UseFormRegister<FormConstructionData>;
  control: Control<FormConstructionData>;
  setValue: UseFormSetValue<FormConstructionData>;
  optionPricedPer: FormConstructionData["optionPricedPer"];
}

const defaultPricingOptions = FormConstructionOptionPricingDefaults;

export const ConstructionOptionPricingFormSection: React.FC<ConstructionOptionPricingFormSectionProps> = (
  { errors, register, control, setValue, optionPricedPer }
) => {
  const { fields, replace, append, remove } = useFieldArray({
    control,
    name: "optionPricing",
  });
  
  useEffect(() => {
    if (optionPricedPer === 'sf') {
      setValue("optionPricedFor","a");
    }
  },[optionPricedPer, setValue]);

  return (
    <>
      <FormSection heading="Option Pricing">
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="optionPricedPer"
              rules={{ required: "Please select a price per." }}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Price Per"
                  placeholder="Enter Per Price"
                  required
                  fullWidth
                  options={getPricePerConstructionOptions.map((perOption) => ({
                    label: getConstructionOptionPricePerDisplayName(perOption),
                    value: perOption,
                  }))}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="optionPricedFor"
              rules={{ required: "Please select a price for." }}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Price For"
                  placeholder="Enter Price For"
                  required
                  fullWidth
                  options={getPriceForConstructionOptions.filter((forOption) => (optionPricedPer === 'sf' && forOption === 'a') || optionPricedPer !== 'sf').map((forOption) => ({
                    label: getConstructionOptionPriceForDisplayName(forOption),
                    value: forOption,
                  }))}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onChange={(e) => {
                    field.onChange(e);

                    const optionPricedForValue = e.target.value;
                    if (optionPricedForValue === "a") {
                      replace(defaultPricingOptions.slice(0, 1));
                    } else {
                      replace(defaultPricingOptions.slice(1));
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        {optionPricedPer !== 'sf' && (
          <Grid container spacing={2}>
            {fields.map((field, index) => (
                <Grid item xs={12} md={6} key={field.id}>
                  <InputField
                    {...register(`optionPricing.${index}.price`, {
                      valueAsNumber: true,
                      required: {
                        value: true,
                        message: "Please enter a value.",
                      },
                      validate: {
                        isNumber: (value) => {
                          return (
                            isValidCurrency(value) ||
                            "Please enter a valid price."
                          );
                        },
                      },
                    })}
                    fullWidth
                    required
                    label={getConstructionOptionPricingDisplayName(field.type)}                  
                    placeholder="Enter Price"
                    error={!!errors.optionPricing?.[index]?.price}
                    helperText={errors.optionPricing?.[index]?.price?.message}
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    intOnly={true}      
                    endAdornment={
                      optionPricedPer !== "u" && optionPricedPer !== "" ? (
                        <InputAdornment position="end">
                          {`/${getConstructionOptionPricePerShortDisplayName(
                            optionPricedPer as ConstructionOptionPricedPerType
                          )}`}
                        </InputAdornment>
                      ) : undefined
                    }
                  />
                </Grid>
            ))}
          </Grid>
        )}
        {optionPricedPer === 'sf' && (
          <>
            {fields.map((field, index) => (
              <React.Fragment key={field.id}>
                {field.type === 'a' && (
                  <Grid container spacing={2} item>
                    <Grid item xs={12} md={3.8}>
                      <InputField
                        {...register(`optionPricing.${index}.price`, {
                          valueAsNumber: true,
                          required: {
                            value: true,
                            message: "Please enter a value.",
                          },
                          validate: {
                            isNumber: (value) => {
                              return (
                                isValidCurrency(value) ||
                                "Please enter a valid price."
                              );
                            },
                          },
                        })}
                        fullWidth
                        required
                        label={getConstructionOptionPricingDisplayName(field.type)}
                        placeholder="Enter Price"
                        error={!!errors.optionPricing?.[index]?.price}
                        helperText={errors.optionPricing?.[index]?.price?.message}
                        type="number"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        intOnly={true}      
                      />
                    </Grid>
                    <Grid item xs={12} md={3.8}>
                      <InputField
                        {...register(`optionPricing.${index}.min`, {
                          valueAsNumber: true,
                          required: {
                            value: true,
                            message: "Please enter a value.",
                          },
                          validate: {
                            isNumber: (value) => {
                              return !(!value || isNaN(value));
                            },
                          },
                        })}
                        fullWidth
                        required
                        label="Minimum Square Feet"
                        placeholder="Enter Minimum"
                        error={!!errors.optionPricing?.[index]?.price}
                        helperText={errors.optionPricing?.[index]?.price?.message}
                        type="number"
                        intOnly={true}      
                      />
                    </Grid>
                    <Grid item xs={12} md={3.8}>
                      <InputField
                        {...register(`optionPricing.${index}.max`, {
                          valueAsNumber: true,
                          required: {
                            value: true,
                            message: "Please enter a value.",
                          },
                          validate: {
                            isNumber: (value) => {
                              return !(!value || isNaN(value));
                            },
                          },
                        })}
                        fullWidth
                        required
                        label="Maximum Square Feet"
                        placeholder="Enter Maximum"
                        error={!!errors.optionPricing?.[index]?.price}
                        helperText={errors.optionPricing?.[index]?.price?.message}
                        type="number"
                        intOnly={true}    
                      />
                    </Grid>
                    <Grid item xs={12} md={0.1}>
                      {index > 0 && (
                        <IconButton
                          sx={{marginTop:2.5}}
                          onClick={() => {
                            remove(fields.length - index);
                          }}
                          color="error"                      
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            ))}
            <Grid item>
                  <IconButton
                    onClick={() => {
                      append({
                        type: "a",
                        price: null,
                        min: null,
                        max: null,
                      });
                    }}
                    color="primary"
                    sx={{fontSize:12}}
                  >
                    <AddCircleOutlineOutlined sx={{fontSize:16,paddingRight:0.6}} />Add Range
                  </IconButton>              
            </Grid>
          </>
        )}
      </FormSection>
    </>
  );
};
