import {
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { FilterSearchButton } from "../../components/FilterSearchButton";
import { MenuItem } from "../../types/menuItem";
import {
  DarkModeOutlined,
  GridView,
  HomeOutlined,
  ListOutlined,
  PersonOutlineOutlined,
  Search,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { FilterSearchDateRangeButton } from "../../components/FilterSearchDateRangeButton";
import { useDetectMobile } from "../../hooks/useDetectMobile";
import { DateTime } from "luxon";
import { useSession } from "../../hooks/useSession";

type FilterSearchProps = {
  isGridView: boolean;
  onViewChange: (isGridView: boolean) => void;
  onSearchTextChange: (searchText: string) => void;
  // Model
  modelOptions: MenuItem[];
  onModelSelect: (modelGuid: string) => void;
  // Retailer
  retailerOptions: MenuItem[];
  onRetailerSelect: (retailerGuid: string) => void;
  // Assignee
  assigneeOptions: MenuItem[];
  onAssigneeSelect: (assigneeGuid: string) => void;
  // Date
  onStartDateSelect: (startDate: DateTime | null) => void;
  onEndDateSelect: (endDate: DateTime | null) => void;
  // Quote Phase
  quotePhaseOptions: MenuItem[];
  onQuotePhaseSelect: (quotePhase: string) => void;
};

const FilterSearch: React.FC<FilterSearchProps> = ({
  isGridView,
  onViewChange,
  onSearchTextChange,
  modelOptions,
  onModelSelect,
  retailerOptions,
  onRetailerSelect,
  assigneeOptions,
  onAssigneeSelect,
  onStartDateSelect,
  onEndDateSelect,
  quotePhaseOptions,
  onQuotePhaseSelect,
}) => {
  const { user } = useSession();
  const isMobile = useDetectMobile();

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent={"space-between"}
      marginBottom={2}
    >
      <Grid item>
        <Stack direction={"row"} spacing={1}>
          <Grid container spacing={1}>
            <Grid item>
              <FilterSearchButton
                icon={<PersonOutlineOutlined />}
                items={assigneeOptions}
                onItemSelect={onAssigneeSelect}
                rememberSelection={true}
              >
                Assignee
              </FilterSearchButton>
            </Grid>
            <Grid item>
              <FilterSearchButton
                icon={<HomeOutlined />}
                items={modelOptions}
                onItemSelect={onModelSelect}
              >
                Model
              </FilterSearchButton>
            </Grid>
            {!user?.isRetailUser && (
              <Grid item>
                <FilterSearchButton
                  icon={<ShoppingCartOutlined />}
                  items={retailerOptions}
                  onItemSelect={onRetailerSelect}
                >
                  Retailer
                </FilterSearchButton>
              </Grid>
            )}
            <Grid item>
              <FilterSearchDateRangeButton
                icon={<CalendarTodayOutlinedIcon />}
                onStartDateSelect={onStartDateSelect}
                onEndDateSelect={onEndDateSelect}
              >
                Date
              </FilterSearchDateRangeButton>
            </Grid>
            <Grid item>
              <FilterSearchButton
                icon={<DarkModeOutlined />}
                items={quotePhaseOptions}
                onItemSelect={onQuotePhaseSelect}
              >
                Quote Phase
              </FilterSearchButton>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item>
        <Stack direction="row" spacing={1}>
          {!isMobile && !user?.isRetailUser && (
            <ToggleButtonGroup
              onChange={(_, value) => {
                if (value == null) return;

                onViewChange(!isGridView);
              }}
              exclusive
              value={isGridView ? "grid" : "list"}
              fullWidth
              sx={{ width: 120 }}
            >
              <ToggleButton value="grid" sx={{ width: 100 }}>
                <GridView />
              </ToggleButton>
              <ToggleButton value="list" sx={{ width: 100 }}>
                <ListOutlined />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          <OutlinedInput
            placeholder="Search"
            size="small"
            color="primary"
            sx={{ backgroundColor: "white" }}
            onChange={(e) => onSearchTextChange(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FilterSearch;
