import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  Switch,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { ConstructionGroup } from "../../types/constructionGroup";
import { useApiRequest } from "../../hooks/useApiRequest";
import { NewEditConstructionGroupModal } from "./NewEditConstructionGroupModal";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { NewEditConstructionSubgroupModal } from "./NewEditConstructionSubgroupModal";
import {
  ConstructionSubgroup,
  getConstructionGroupTypeDisplayName,
} from "../../types/constructionSubgroup";
import { GridColDef } from "@mui/x-data-grid";
import { DraggableList } from "../../components/DraggableList";
import { AccordionCardDraggable } from "../../components/AccordionCardDraggable";
import { TableDraggable } from "../../components/TableDraggable";
import { updateSortOrder } from "../../utils/array";
import { AccordionCard } from "../../components/AccordionCard";
import { generatePath, useNavigate } from "react-router-dom";

const ManageConstructionOptionsRoute: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [isEditingSubGroup, setIsEditingSubGroup] = useState(false);
  const [editingGroup, setEditingGroup] = useState<
    ConstructionGroup | undefined
  >(undefined);
  const [editingSubGroup, setEditingSubGroup] = useState<
    ConstructionSubgroup | undefined
  >(undefined);

  const [filters, setFilters] = useState<PageFilters>({
    searchText: "",
    subgroupType: "",
  });

  const { data: groupsData, request: groupsRequest } =
    useApiRequest<ConstructionGroup[]>(false);
  const { data: groupResponse, request: groupRequest } = useApiRequest();
  const { request: updateSortOrderRequest } = useApiRequest<[]>(false);

  const [constructionGroups, setConstructionGroups] = useState<
    ConstructionGroup[]
  >(groupsData ?? []);

  usePageMetadata({
    title: `Manage ${routes.manageConstructionCosts.label}`,
  });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageConstructionCosts.label }]);
  }, [setBreadcrumbs]);

  const refreshGroups = useCallback(() => {
    groupsRequest("/retailer/constoptions/groups", { method: "GET" });
  }, [groupsRequest]);

  useEffect(() => {
    refreshGroups();
  }, [refreshGroups, groupResponse]);

  useEffect(() => {
    setConstructionGroups(groupsData ?? []);
  }, [groupsData]);

  const handleNewButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setEditingGroup(undefined);
    setIsEditingGroup(true);
  };

  const handleEditGroupClick = (group: ConstructionGroup) => () => {
    setEditingGroup(group);
    setIsEditingGroup(true);
  };

  const handleSaveGroup = useCallback(() => {
    refreshGroups();
    setIsEditingGroup(false);
  }, [refreshGroups]);

  const handleSaveSubGroup = useCallback(() => {
    refreshGroups();
    setIsEditingGroup(false);
    setIsEditingSubGroup(false);
  }, [refreshGroups]);

  const handleAddSubgroupClick =
    (group: ConstructionGroup) => (e: React.MouseEvent) => {
      e.stopPropagation();
      setEditingSubGroup(undefined);
      setEditingGroup(group);
      setIsEditingSubGroup(true);
    };

  const handleActiveToggle = useCallback(
    (subgroup: ConstructionSubgroup) => () => {
      groupRequest(
        `retailer/constoptions/subgroups/${subgroup.constructionSubgroupGuid}/edit`,
        {
          method: "POST",
          data: { isActive: !subgroup.isActive },
        }
      );
    },[groupRequest]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchText: event.target.value.toLowerCase(),
      }));
    },
    []
  );

  const handleEditClick = useCallback(
    (subgroup: ConstructionSubgroup) => () => {
      setEditingSubGroup(subgroup);
      setIsEditingSubGroup(true);
    },
    []
  );
  const columns: GridColDef<ConstructionSubgroup>[] = useMemo(
    () => [
      { field: "subgroupName", headerName: "Name", flex: 1 },
      {
        field: "subgroupType",
        headerName: "Type",
        flex: 1,
        valueFormatter: getConstructionGroupTypeDisplayName,
      },
      {
        field: "isActive",
        headerName: "Active",
        renderCell: (params) => {
          return (
            <Box sx={{ marginLeft: -1 }}>
              <Switch
                checked={params.row.isActive}
                onChange={handleActiveToggle(params.row)}
              />
            </Box>
          );
        },
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        width: 65,
        renderCell: ({ row }) => (
          <MoreMenuButton
            menuItems={[
              {
                label: "Edit",
                onClick: handleEditClick(row),
              },
              {
                label: "View Options",
                onClick: () => {
                  navigate(
                    generatePath(
                      routes.manageDataConstructionCostsSubgroup.path,
                      {
                        constructionSubgroupGuid: row.constructionSubgroupGuid,
                      }
                    )
                  );
                },
              },
            ]}
          />
        ),
      },
    ],
    [handleEditClick, handleActiveToggle, navigate]
  );

  const handleDragEndGroups = (sortedGroups: ConstructionGroup[]) => {
    setConstructionGroups((prevGroups) => {
      const updatedGroups = updateSortOrder(
        [...sortedGroups, ...prevGroups],
        (a, b) => a.constructionGroupGuid === b.constructionGroupGuid
      );
      updateSortOrderRequest("/retailer/constoptions/groups/sortorder/update", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: updatedGroups.map((g) => ({
          constructionGroupGuid: g.constructionGroupGuid,
          sortOrder: g.sortOrder,
        })),
      });

      return updatedGroups;
    });
  };

  const handleDragEndSubgroups = (
    group: ConstructionGroup,
    sortedSubgroups: ConstructionSubgroup[]
  ) => {
    setConstructionGroups((prevGroups) => {
      return prevGroups.map((prevGroup) => {
        if (prevGroup.constructionGroupGuid !== group.constructionGroupGuid) {
          return prevGroup;
        }

        const updatedSubgroups = updateSortOrder(
          [...sortedSubgroups, ...prevGroup.subgroups],
          (a, b) => a.constructionSubgroupGuid === b.constructionSubgroupGuid
        );
        updateSortOrderRequest(
          "/retailer/constoptions/subgroups/sortorder/update",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: updatedSubgroups.map((s) => ({
              constructionSubgroupGuid: s.constructionSubgroupGuid,
              sortOrder: s.sortOrder,
            })),
          }
        );

        return { ...prevGroup, subgroups: updatedSubgroups };
      });
    });
  };

  const getGroupDragId = (group: ConstructionGroup) =>
    group.constructionGroupGuid;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
        marginBottom={2}
      >
        <Grid item>
          <Stack spacing={2} direction={"row-reverse"}>
            <OutlinedInput
              placeholder="Search"
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              value={filters.searchText}
              onChange={handleSearchChange}
            />
          </Stack>
        </Grid>
      </Grid>
      <AccordionCard
        key="category"
        heading="Construction Costs"
        expanded={true}
        endActions={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleNewButtonClick}
            >
              Group
            </Button>
          </Stack>
        }
      >
        <Box>
          <DraggableList
            items={getFilteredGroups(constructionGroups, filters)}
            getItemDragId={getGroupDragId}
            onDragEnd={handleDragEndGroups}
          >
            {getFilteredGroups(constructionGroups, filters).map((group) => (
              <AccordionCardDraggable
                key={getGroupDragId(group)}
                dragId={getGroupDragId(group)}
                heading={group.groupName}
                headingProps={{ fontWeight: "bold" }}
                endActions={
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <MoreMenuButton
                      menuItems={[
                        {
                          label: "Edit Name",
                          onClick: handleEditGroupClick(group),
                        },
                      ]}
                    />
                  </Stack>
                }
                detailsSx={{ padding: 0, pb: 1 }}
                expanded={true}
              >
                {group.subgroups.length > 0 && (
                  <Box border={(theme) => `1px solid ${theme.palette.divider}`}>
                    <TableDraggable
                      columns={columns}
                      rows={group.subgroups}
                      rowSelection={false}
                      hideFooter
                      disableColumnFilter
                      disableColumnMenu
                      disableColumnResize
                      disableRowSelectionOnClick
                      getRowId={(row) => row.constructionSubgroupGuid}
                      onDragEnd={(sortedSubgroups) =>
                        handleDragEndSubgroups(group, sortedSubgroups)
                      }
                    />
                  </Box>
                )}
                <Button
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={handleAddSubgroupClick(group)}
                  sx={{ margin: 2, ml: 1.2, mb: 0 }}
                >
                  Subgroup
                </Button>
              </AccordionCardDraggable>
            ))}
          </DraggableList>
        </Box>
        {isEditingGroup && (
          <NewEditConstructionGroupModal
            isOpen={isEditingGroup}
            onClose={() => setIsEditingGroup(false)}
            onSaveSuccessful={handleSaveGroup}
            group={editingGroup}
            isNew={!editingGroup}
          />
        )}
        {isEditingSubGroup && (
          <NewEditConstructionSubgroupModal
            isOpen={isEditingSubGroup}
            onClose={() => setIsEditingSubGroup(false)}
            onSaveSuccessful={handleSaveSubGroup}
            constructionGroup={editingGroup!}
            constructionSubgroup={editingSubGroup}
            isNew={!editingSubGroup}
          />
        )}
      </AccordionCard>
    </>
  );
};

type PageFilters = {
  subgroupType: string;
  searchText: string;
};

const isFilteringPage = (filters: PageFilters): boolean => {
  return filters.subgroupType.length > 0 || filters.searchText.length > 0;
};

const getFilteredGroups = (
  groups: ConstructionGroup[],
  filters: PageFilters
): ConstructionGroup[] => {
  const isFiltering = isFilteringPage(filters);

  if (!isFiltering) {
    return groups;
  }

  return groups
    .map((group) => {
      const groupMatchesSearchText = group.groupName
        .toLowerCase()
        .includes(filters.searchText.toLowerCase());

      const filteredSubgroups = group.subgroups.filter((subgroup) => {
        let matchesFilters = true;
        const subgroupMatchesSearchText = subgroup.subgroupName
          .toLowerCase()
          .includes(filters.searchText.toLowerCase());

        if (filters.subgroupType) {
          matchesFilters = subgroup.subgroupType === filters.subgroupType;
        }

        if (matchesFilters && filters.searchText) {
          matchesFilters = subgroupMatchesSearchText || groupMatchesSearchText;
        }

        return matchesFilters;
      });

      return groupMatchesSearchText || filteredSubgroups.length > 0
        ? { ...group, subgroups: filteredSubgroups }
        : null;
    })
    .filter(Boolean) as ConstructionGroup[];
};

export default ManageConstructionOptionsRoute;
