import React from "react";
import { Box, Button, InputAdornment } from "@mui/material";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { Modal } from "../../components/Modal";
import { OrderCardBusinessHome } from "../../types/orderCardBusinessHome";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";

export type FormData = {
  width: number;
  bedrooms: number;
  bathrooms: number;
  halfbaths: number;
  sections: { totalSpace: number; length: number; }[];
};

interface OrderCardBusinessHomeEditModalProps {
  orderCardData: OrderCardBusinessHome["cardData"];
  onSaveSuccessful: (data: FormData) => void;
  onClose: () => void;
}

const getDefaultValues = (
  orderCardData: OrderCardBusinessHome["cardData"]
): FormData => {
  return {
    width: orderCardData.totalWidth / orderCardData.sections.length,
    bedrooms: orderCardData.bedrooms,
    bathrooms: orderCardData.bathrooms,
    halfbaths: orderCardData.halfbaths,
    sections: orderCardData.sections,
  };
};

export const OrderCardBusinessHomeEditModal: React.FC<
  OrderCardBusinessHomeEditModalProps
> = ({ orderCardData, onSaveSuccessful, onClose }) => {
  const { handleSubmit, formState, register, control } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCardData),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const widthSelectionOptions = [{label: '14', value: '14'},{label: '16', value: '16'}];

  const onSubmit = (data: FormData) => {
    onSaveSuccessful(data);
  };

  const { fields } = useFieldArray({
    control,
    name: "sections",
  });

  return (
    <Modal heading={"Edit Home Information"} isOpen={true} onClose={onClose}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 2, width: 488 }}
      >
        <Controller
          control={control}
          name="width"
          render={({ field, fieldState }) => (
            <SelectField
              {...field}
              label="Section Width"
              placeholder="Select Section Width"
              required
              options={widthSelectionOptions}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              sx={{ flex: 1, marginBottom: 2 }}
            />
          )}
        />
        <InputField
          {...register("bedrooms", {
            valueAsNumber: true,
          })}
          label="Bedrooms"
          type="number"
          required
          error={!!formState.errors.bedrooms}
          helperText={formState.errors.bedrooms?.message}
          intOnly={true}
          sx={{ flex: 1 }}
        />
        <InputField
          {...register("bathrooms", {
            valueAsNumber: true,
          })}
          label="Full Bathrooms"
          type="number"
          required
          error={!!formState.errors.bathrooms}
          helperText={formState.errors.bathrooms?.message}
          intOnly={true}
          sx={{ flex: 1 }}
        />
        <InputField
          {...register("halfbaths", {
            valueAsNumber: true,
          })}
          label="Half Bathrooms"
          type="number"
          required
          error={!!formState.errors.halfbaths}
          helperText={formState.errors.halfbaths?.message}
          intOnly={true}
          sx={{ flex: 1 }}
        />
        {fields.map((field, index) => (
          <div key={field.id}>
            <InputField
              {...register(`sections.${index}.length`, { valueAsNumber: true })}
              label={`Section ${index + 1} Length`}
              type="number"
              required
              error={!!formState.errors.sections?.[index]?.length}
              helperText={formState.errors.sections?.[index]?.length?.message}
              intOnly
              sx={{ flex: 1, marginRight: 2 }}
            />

            <InputField
              {...register(`sections.${index}.totalSpace`, { valueAsNumber: true })}
              label={`Section ${index + 1} Total Space`}
              type="number"
              required
              error={!!formState.errors.sections?.[index]?.totalSpace}
              helperText={formState.errors.sections?.[index]?.totalSpace?.message}
              intOnly
              sx={{ flex: 1 }}
              endAdornment={<InputAdornment position="end">sq ft</InputAdornment>}
            />
          </div>
        ))}

        <Box sx={{ mt: 2, display: "flex", justifyContent: "end", width: "100%" }}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!formState.isValid}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
