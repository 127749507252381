import { memo, useMemo, Dispatch, SetStateAction } from "react";
import { Stack } from "@mui/material";
import { OrderCard } from "../../types/orderCard";
import { OrderCardBusinessLogistics as OrderCardBusinessLogisticsType } from "../../types/orderCardBusinessLogistics";
import { orderCardTypes } from "../../types/orderCardTypes";
import { OrderCardBusinessBuyer } from "./OrderCardBusinessBuyer";
import { OrderCardBusinessHome } from "./OrderCardBusinessHome";
import { OrderCardBusinessLogistics } from "./OrderCardBusinessLogistics";
import { OrderCardConstructionOption } from "./OrderCardConstructionOption";
import { OrderCardCustomSubgroup } from "./OrderCardCustomSubgroup";
import { OrderCardFinancialFees } from "./OrderCardFinancialFees";
import { OrderCardRetailerFees } from "./OrderCardRetailerFees";
import { OrderCardFinancialFinancing } from "./OrderCardFinancialFinancing";
import { OrderCardFinancialPricing } from "./OrderCardFinancialPricing";
import { OrderCardSubgroupOption } from "./OrderCardSubgroupOption";
import { OrderCardInvoiceSection } from "./OrderCardInvoiceSection";
import { OrderCardEditResponse } from "./orderCardEditResponse";
import { OrderCardProvider } from "./OrderCardContext";
import { Order } from "../../types/order";
import { OrderCardInvoiceDates } from "./OrderCardInvoiceDates";
import { OrderCardHeatUValues } from "./OrderCardHeatUValue";
import { OrderCardAppliance } from "./OrderCardAppliance";
import { OrderCardInvoiceSpecifications } from "./OrderCardInvoiceSpecifications";
import { OrderCardInvoiceHeatingCoolingDucts } from "./OrderCardInvoiceHeatingCoolingDucts";
import { OrderCardInvoiceInsulation } from "./OrderCardInvoiceInsulation";
import { OrderCardAddAppliance } from "./OrderCardAddAppliance";
import { OrderCardSalesChangeOrder } from "./OrderCardSalesChangeOrder";
import { OrderCardAddSalesChangeOrder } from "./OrderCardAddSalesChangeOrder";
import { OrderCardSalesChangeOrder as OrderCardSalesChangeOrderType } from "../../types/orderCardSalesChangeOrder";
import { OrderCardAdditionalBuyerQuestions } from "./OrderCardAdditionalBuyerQuestions";
import { LoadingOverlay } from "../../components/LoadingOverlay";

interface OrderCardListProps {
  order: Order;
  orderCards: OrderCard[];
  onOrderCardEdited: (
    orderCardEditResponse: OrderCardEditResponse<OrderCard>
  ) => void;
  onCurrentCardInViewChange: (orderCardGuid: OrderCard) => void;
  onCurrentEditingOrderCardChange: (orderCardGuid: OrderCard | null) => void;
  onAddSalesChangeOrder: (orderCard: OrderCardSalesChangeOrderType) => void;
  expanded: boolean;
  refreshOrder: () => void;
  jumpToCardGuid: string | null;
  isLoading: boolean;
  onOrderCardRefreshed: (
    orderCardGetResponse: OrderCard
  ) => void,
  saveOrderCardCount: number;
  setSaveOrderCardCount: (value: number) => void;
  setOrder: Dispatch<SetStateAction<Order | null>>;
}

export const OrderCardList: React.FC<OrderCardListProps> = memo(
  ({
    order,
    orderCards,
    onOrderCardEdited,
    onCurrentCardInViewChange,
    onCurrentEditingOrderCardChange,
    onAddSalesChangeOrder,
    expanded,
    refreshOrder,
    jumpToCardGuid,
    isLoading,
    onOrderCardRefreshed,
    saveOrderCardCount,
    setSaveOrderCardCount,
    setOrder,
  }) => {
    const orderCardGroupIndexes = useMemo(() => {
      const indexes: Record<OrderCard["orderCardGuid"], number> = {};

      orderCards.forEach((orderCard, index) => {
        const prevOrderCard = index > 0 ? orderCards[index - 1] : null;

        if (prevOrderCard?.groupName !== orderCard.groupName) {
          // First in group.
          indexes[orderCard.orderCardGuid] = 0;
        } else {
          // Next in group.
          const previousOrderCardIndex = indexes[prevOrderCard.orderCardGuid];
          indexes[orderCard.orderCardGuid] = previousOrderCardIndex + 1;
        }
      });

      return indexes;
    }, [orderCards]);

    const getOrderCard = (orderCard: OrderCard, groupIndex: number) => {
      switch (orderCard.orderCardType) {
        case orderCardTypes.businessLogistics:
          return (
            <OrderCardBusinessLogistics
              expanded={expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
              order={order}
            />
          );
        case orderCardTypes.businessBuyer:
          const orderCardBusinessLogistics = orderCards.find(
            (o) => o.orderCardType === orderCardTypes.businessLogistics
          );
          if (order.orderStatus !== "p" && !orderCardBusinessLogistics) {
            // eslint-disable-next-line no-console
            console.error(
              "Unable to render business buyer card. Could not locate logistics card."
            );
            return null;
          }

          return (
            <OrderCardBusinessBuyer
              order={order}
              expanded={order.orderStatus === "p" || expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
              orderCardBusinessLogistics={
                orderCardBusinessLogistics as OrderCardBusinessLogisticsType
              }
            />
          );
        case orderCardTypes.businessHome:
          return (
            <OrderCardBusinessHome
              expanded={expanded}
              groupIndex={groupIndex}
              order={order}
              orderCard={orderCard}
              refreshOrder={refreshOrder}
            />
          );
        case orderCardTypes.subgroupOption:
          return (
            <OrderCardSubgroupOption
              expanded={expanded}
              groupIndex={groupIndex}
              order={order}
              orderCard={orderCard}
              refreshOrder={refreshOrder}
              onOrderCardEdited={onOrderCardEdited}
              setOrder={setOrder}
            />
          );
        case orderCardTypes.customSubgroup:
          return (
            <OrderCardCustomSubgroup
              order={order}
              expanded={expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
              refreshOrder={refreshOrder}
              onOrderCardEdited={onOrderCardEdited}
              setOrder={setOrder}
            />
          );
        case orderCardTypes.financialPricing:
          return (
            <OrderCardFinancialPricing
              expanded={expanded}
              groupIndex={groupIndex}
              isTemplate={order.isTemplate}
              orderCard={orderCard}
            />
          );
        case orderCardTypes.financialFinancing:
          return (
            <OrderCardFinancialFinancing
              expanded={expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
            />
          );
        case orderCardTypes.financialFees:
          return (
            <OrderCardFinancialFees
              expanded={expanded}
              groupIndex={groupIndex}
              isTemplate={order.isTemplate}
              orderCard={orderCard}
              order={order}
              onOrderCardEdited={onOrderCardEdited}
            />
          );
        case orderCardTypes.retailerFees:
          return (
            <OrderCardRetailerFees
              expanded={expanded}
              groupIndex={groupIndex}
              isTemplate={order.isTemplate}
              orderCard={orderCard}
            />
          );
        case orderCardTypes.invoiceDates:
          return (
            <OrderCardInvoiceDates
              expanded={expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
            />
          );
        case orderCardTypes.invoiceSection:
          return (
            <OrderCardInvoiceSection
              expanded={expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
            />
          );
        case orderCardTypes.heatUValue:
          return (
            <OrderCardHeatUValues
              expanded={expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
            />
          );
        case orderCardTypes.invoiceAppliances:
          return (
            <OrderCardAppliance
              order={order}
              expanded={expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
              refreshOrder={refreshOrder}
            />
          );
        case orderCardTypes.invoiceSpecifications:
          return (
            <OrderCardInvoiceSpecifications
              expanded={expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
            />
          );
        case orderCardTypes.invoiceHeatingCoolingDucts:
          return (
            <OrderCardInvoiceHeatingCoolingDucts
              expanded={expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
            />
          );
        case orderCardTypes.invoiceCeilingInsulation:
        case orderCardTypes.invoiceExteriorWallInsulation:
        case orderCardTypes.invoiceFloorInsulation:
          return (
            <OrderCardInvoiceInsulation
              expanded={expanded}
              groupIndex={groupIndex}
              orderCard={orderCard}
            />
          );
        case orderCardTypes.addAppliance:
          return (
            <OrderCardAddAppliance
              expanded={expanded}
              groupIndex={groupIndex}
              order={order}
              orderCard={orderCard}
              refreshOrder={refreshOrder}
            />
          );
        case orderCardTypes.salesChangeOrder:
          return (
            <OrderCardSalesChangeOrder
              expanded={expanded}
              groupIndex={groupIndex}
              order={order}
              orderCard={orderCard}
              refreshOrder={refreshOrder}
              onOrderCardEdited={onOrderCardEdited}
              onAddSalesChangeOrder={onAddSalesChangeOrder}
            />
          );
        case orderCardTypes.addSalesChangeOrder:
          return (
            <OrderCardAddSalesChangeOrder
              expanded={expanded}
              groupIndex={groupIndex}
              order={order}
              orderCard={orderCard}
              onAddSalesChangeOrder={onAddSalesChangeOrder}
            />
          );
        case orderCardTypes.additionalBuyerQuestions:
          return <OrderCardAdditionalBuyerQuestions orderCard={orderCard} />;
        case orderCardTypes.constructionOption:
          return (
            <OrderCardConstructionOption
              expanded={expanded}
              groupIndex={groupIndex}
              order={order}
              orderCard={orderCard}
              setOrder={setOrder}
            />
          );  
        default:
          // This allows typescript to report an error if we add a new Order
          // Card. That way we ensure we explicitly handle every order type.
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const unhandledItem: never = orderCard;
          return null;
      }
    };

    return (
      <>
        {isLoading && <LoadingOverlay />}

        <Stack spacing={3}>
          {orderCards.map((orderCard) => {
            const groupIndex = orderCardGroupIndexes[orderCard.orderCardGuid];

            return (
              <OrderCardProvider
                key={orderCard.orderCardGuid}
                onOrderCardEdited={onOrderCardEdited}
                onCurrentCardInViewChange={onCurrentCardInViewChange}
                onCurrentEditingOrderCardChange={
                  onCurrentEditingOrderCardChange
                }
                jumpToCardGuid={jumpToCardGuid}
                orderCard={orderCard}
                onOrderCardRefreshed={onOrderCardRefreshed}
                saveOrderCardCount={saveOrderCardCount}
                setSaveOrderCardCount={setSaveOrderCardCount}
              >
                {getOrderCard(orderCard, groupIndex)}
              </OrderCardProvider>
            );
          })}
        </Stack>
      </>
    );
  }
);
