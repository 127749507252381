import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ConfirmModal } from "./ConfirmModal";
import { useGlobalToastNotificationContext } from "../context/GlobalToastNotificationContext";
import { useApiRequest } from "../hooks/useApiRequest";
import { ConstructionOption } from "../types/constructionOption";

type DeleteConstructionOptionModalProps = {
  constructionOption: ConstructionOption;
  onClose: () => void;
  onDeleteSuccess: () => void;
};

export const DeleteConstructionOptionModal: React.FC<DeleteConstructionOptionModalProps> = ({
  constructionOption,
  onClose,
  onDeleteSuccess,
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<String | null>(null);

  const {
    request: deleteConstructionOptionRequest,
    loading: deleteConstructionOptionLoading,
    errorMessage: deleteConstructionOptionErrorMessage,
    response: deleteConstructionOptionResponse,
  } = useApiRequest(false);

  useEffect(() => {
    if (deleteConstructionOptionErrorMessage) {
      showErrorToast({ message: deleteConstructionOptionErrorMessage });
    }
  }, [deleteConstructionOptionErrorMessage, showErrorToast]);

  useEffect(() => {
    if (deleteConstructionOptionResponse) {
      if (deleteConstructionOptionResponse.data.result.error) {
        setDeleteErrorMessage(deleteConstructionOptionResponse.data.result.error);
      } else {
        onDeleteSuccess();
      }
    }
  }, [deleteConstructionOptionResponse, onDeleteSuccess]);

  const handleDeleteClick = () => {
    deleteConstructionOptionRequest(`/retailer/constoptions/${constructionOption.constructionOptionGuid}/delete`, {
      method: "DELETE",
    });
  };

  return (
    <>
      {deleteErrorMessage ? (
        <ConfirmModal
          isOpen={true}
          heading={
            `Unable to delete this selection option.`
          }
          body={
            <Typography variant="body2" color="grey.500">
              {deleteErrorMessage}
            </Typography>
          }
          confirmButtonText="Ok"
          confirmOnly={true}
          onConfirm={onClose}
          onClose={onClose}
        />
      ) : (
        <ConfirmModal
          isOpen={true}
          heading={
            `Delete option "${constructionOption.optionName}"`
          }
          body={
            <Typography variant="body2" color="grey.500">
              This cannot be undone. Delete it?
            </Typography>
          }
          confirmButtonText="Delete"
          isDestructive={true}
          onConfirm={handleDeleteClick}
          onClose={onClose}
          confirmDisabled={deleteConstructionOptionLoading}
        />        
      )}
    </>
  );
};
