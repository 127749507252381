export type Logic = {
  logicGuid: string;
  ifType: "o" | "a";
  ifOptionGuid: string;
  ifOptionName: string;
  ifAttributeselectionGuid: string | null;
  ifAttributeselectionName: string | null;
  thenRequireHide: "r" | "h";
  thisType: "o" | "a" | "s";
  thisOptionGuid: string;
  thisOptionName: string;
  thisAttributeselectionGuid: string | null;
  thisAttributeselectionName: string | null;
  thisSubgroupGuid: string | null;
  thisSubgroupName: string | null;
  isActive: boolean;
  logicError: {
    shortDesc: string;
    longDesc: string;
  } | null;
  thisTargets: {
    thisAttributeselectionGuid: string | null;
    thisOptionGuid: string | null;
    thisSubgroupGuid: string | null;
    thisType: "o" | "a" | "s";
    thisOrderCardIndex: number[] | null;
  }[];
};

type LogicIfType = Logic["ifType"];
type LogicRequireHide = Logic["thenRequireHide"];
type LogicThisType = Logic["thisType"];
export type LogicThisTarget = Logic['thisTargets'][number];

export const getLogicThenRequireHideAsDisplayName = (
  thenRequireHide: LogicRequireHide
): string => {
  const displayNames: Record<LogicRequireHide, string> = {
    r: "Require",
    h: "Hide",
  };

  return displayNames[thenRequireHide] || thenRequireHide;
};

export const getLogicIfTypeAsDisplayName = (ifType: LogicIfType): string => {
  const displayNames: Record<LogicIfType, string> = {
    o: "Selection Option",
    a: "Selection Option + Attribute",
  };

  return displayNames[ifType] || ifType;
};

export const getLogicThisTypeAsDisplayName = (
  thisType: LogicThisType
): string => {
  const displayNames: Record<LogicThisType, string> = {
    o: "Selection Option",
    a: "Selection Option + Attribute",
    s: "Sub-Group",
  };

  return displayNames[thisType] || thisType;
};
