import { Typography } from "@mui/material";
import { ConfirmModal } from "../../components/ConfirmModal";

type LookupErrorModalProps = {
  onClose: () => void;
};

export const LookupErrorModal: React.FC<LookupErrorModalProps> = ({
  onClose,
}) => {
  return (
    <ConfirmModal
      isOpen={true}
      onConfirm={onClose}
      onClose={onClose}
      heading="Record not found"
      confirmButtonText="Okay"
      confirmOnly
      body={
        <Typography variant="body2" color="grey.500">
          Please check the order identifier.
        </Typography>
      }
    />
  );
};
