import { Grid } from "@mui/material";
import { FormSection } from "../../components/FormSection";
import { FormConstructionData } from "./FormConstructionData";
import {
  Control,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import InputField from "../../components/InputField";

interface ConstructionOptionDetailsFormSectionProps {
  errors: FieldErrors<FormConstructionData>;
  register: UseFormRegister<FormConstructionData>;
  control: Control<FormConstructionData>;
}

export const ConstructionOptionDetailsFormSection: React.FC<
ConstructionOptionDetailsFormSectionProps
> = ({
  errors,
  register,
}) => {

  return (
    <>
      <FormSection heading="Option Details">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <InputField
              {...register("optionName", {
                required: {
                  value: true,
                  message: "Please enter a value.",
                },
              })}
              fullWidth
              required
              label="Option Name"
              placeholder="Enter option name"
              error={!!errors.optionName}
              helperText={errors.optionName?.message}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  );
};
