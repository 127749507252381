import {
  createBrowserRouter,
  LoaderFunction,
  redirect,
} from "react-router-dom";
import { MainLayout } from "./components/MainLayout";

import HomeRedirect from "./components/HomeRedirect";

import TermsRoute from "./routes/TermsRoute";

// User Session Routes
import LoginRoute from "./routes/LoginRoute";
import PasswordChangeRoute from "./routes/PasswordChangeRoute";

// Manage Routes
import ManageSysAdminsRoute from "./routes/ManageSystemAdminsRoute";
import ManageUsersRoute from "./routes/ManageUsersRoute";
import ManageTenantsRoute from "./routes/ManageTenantsRoute";
import ManageModelSeriesRoute from "./routes/ManageModelSeriesRoute";
import ManageModelSeriesAddEditRoute from "./routes/ManageModelSeriesAddEditRoute";
import ManageSelectionOptionsRoute from "./routes/ManageSelectionOptionsRoute";
import ManageSelectionOptionsAddEditRoute from "./routes/ManageSelectionOptionsAddEditRoute";
import ManageRetailersRoute from "./routes/ManageRetailersRoute";
import ManageRetailersDetailRoute from "./routes/ManageRetailersDetailRoute";
import ManageCompanyRoute from "./routes/ManageCompanyRoute";
import ManageTemplatesRoute from "./routes/ManageTemplatesRoute";
import ManageFreightRoute from "./routes/ManageFreightRoute";
import ManageInvoiceDisclosuresRoute from "./routes/ManageInvoiceDisclosuresRoute";
import ManageStateFeesRoute from "./routes/ManageStateFeesRoute";
import ManageSurchargeCalculatorRoute from "./routes/ManageSurchargeCalculatorRoute";
import ManageVendorsRoute from "./routes/ManageVendorsRoute";
import ManageConditionalLogicRoute from "./routes/ManageConditionalLogicRoute";
import TenantUsersRoute from "./routes/TenantUsersRoute";
import ManageSelectionOptionsSubgroupRoute from "./routes/ManageSelectionOptionsSubGroupRoute";
import ManageAttributesRoute from "./routes/ManageAttributesRoute";
import ManageAttributesDetailRoute from "./routes/ManageAttributesDetailRoute";
import ManageConstructionCostsRoute from "./routes/ManageConstructionCostsRoute";
import ManageConstructionCostsSubgroupRoute from "./routes/ManageConstructionCostsSubgroupRoute";
import ManageConstructionOptionsAddEditRoute from "./routes/ManageConstructionOptionsAddEditRoute";

import Reporting from "./routes/ReportingRoute";
import ReportingAddEditRoute from "./routes/ReportingAddEditRoute";
import { ReportingAddEditRouteLayout } from "./routes/ReportingAddEditRoute/ReportingAddEditRouteLayout";

import QuotesAndOrdersRoute from "./routes/QuotesRoute";
import QuotesAndOrdersDetailRoute from "./routes/QuotesDetailRoute";
import { QuotesRouteLayout as QuotesAndOrdersRouteLayout } from "./routes/QuotesRoute/QuotesRouteLayout";

import NotFound from "./routes/NotFoundRoute";
import { session } from "./context/SessionContext";
import EmptyRoute from "./routes/EmptyRoute";
import UpdateProfileRoute from "./routes/UpdateProfileRoute";

import { canAccessRoute, RouteConfig, routes } from "./routes";
import { SidebarLayoutManageData } from "./components/SidebarLayoutManageData";
import { RouteErrorBoundary } from "./components/RouteErrorBoundary";

import BuyerModeRoute from "./routes/BuyerModeRoute";
import ManageConditionalLogicAddEditRoute from "./routes/ManageConditionalLogicAddEditRoute";
import RetailerInventory from "./routes/RetailerInventoryRoute";

/**
 * Generates a react-router LoaderFunction that can be used to restrict access
 * to a page based on the provided route config.
 */
const authenticatedLoader =
  (routeConfig: RouteConfig): LoaderFunction =>
  () => {
    const user = session.getUser();
    if (!user) {
      return redirect(routes.login.path);
    }

    if (!canAccessRoute(user, routeConfig)) {
      return redirect("/not-found");
    }

    return null;
  };

export const router = createBrowserRouter([
  {
    path: routes.login.path,
    element: <LoginRoute />,
    ErrorBoundary: RouteErrorBoundary,
  },
  {
    path: routes.terms.path,
    element: <TermsRoute />,
    ErrorBoundary: RouteErrorBoundary,
  },
  {
    path: routes.resetPassword.path,
    element: <PasswordChangeRoute />,
    ErrorBoundary: RouteErrorBoundary,
  },
  {
    path: routes.buyerMode.path,
    element: <BuyerModeRoute />,
    ErrorBoundary: RouteErrorBoundary,
  },
  {
    path: routes.home.path,
    element: <MainLayout />,
    ErrorBoundary: RouteErrorBoundary,
    children: [
      { path: "*", element: <NotFound />, ErrorBoundary: RouteErrorBoundary },
      {
        index: true,
        element: <HomeRedirect />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.reporting.path,
        element: <Reporting />,
        loader: authenticatedLoader(routes.reporting),
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.profile.path,
        element: <UpdateProfileRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageTenants.path,
        element: <ManageTenantsRoute />,
        loader: authenticatedLoader(routes.manageTenants),
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.tenantUsers.path,
        element: <TenantUsersRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageSystemAdmins.path,
        element: <ManageSysAdminsRoute />,
        loader: authenticatedLoader(routes.manageSystemAdmins),
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageData.path,
        element: <SidebarLayoutManageData />,
        ErrorBoundary: RouteErrorBoundary,
        children: [
          {
            index: true,
            element: (
              <EmptyRoute
                breadcrumbs={[{ label: "Data Management" }]}
                title="Data Management"
              />
            ),
          },
          {
            path: routes.manageDataCompany.path,
            element: <ManageCompanyRoute />,
            loader: authenticatedLoader(routes.manageDataCompany),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.retailerInventory.path,
            element: <RetailerInventory />,
            loader: authenticatedLoader(routes.retailerInventory),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataModelSeries.path,
            element: <ManageModelSeriesRoute />,
            loader: authenticatedLoader(routes.manageDataModelSeries),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataSelectionOptions.path,
            element: <ManageSelectionOptionsRoute />,
            loader: authenticatedLoader(routes.manageDataSelectionOptions),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataAttributes.path,
            element: <ManageAttributesRoute />,
            loader: authenticatedLoader(routes.manageDataAttributes),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataRetailers.path,
            element: <ManageRetailersRoute />,
            loader: authenticatedLoader(routes.manageDataRetailers),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataVendors.path,
            element: <ManageVendorsRoute />,
            loader: authenticatedLoader(routes.manageDataVendors),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataUsers.path,
            element: <ManageUsersRoute />,
            loader: authenticatedLoader(routes.manageDataUsers),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataTemplates.path,
            element: <ManageTemplatesRoute />,
            loader: authenticatedLoader(routes.manageDataTemplates),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataFreight.path,
            element: <ManageFreightRoute />,
            loader: authenticatedLoader(routes.manageDataFreight),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataInvoiceDisclosures.path,
            element: <ManageInvoiceDisclosuresRoute />,
            loader: authenticatedLoader(routes.manageDataInvoiceDisclosures),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataStateFees.path,
            element: <ManageStateFeesRoute />,
            loader: authenticatedLoader(routes.manageDataStateFees),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataSurchargeCalculator.path,
            element: <ManageSurchargeCalculatorRoute />,
            loader: authenticatedLoader(routes.manageDataSurchargeCalculator),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageDataConditionalLogic.path,
            element: <ManageConditionalLogicRoute />,
            loader: authenticatedLoader(routes.manageDataConditionalLogic),
            ErrorBoundary: RouteErrorBoundary,
          },
          {
            path: routes.manageConstructionCosts.path,
            element: <ManageConstructionCostsRoute />,
            loader: authenticatedLoader(routes.manageConstructionCosts),
            ErrorBoundary: RouteErrorBoundary,
          },
        ],
      },
      {
        path: routes.manageDataConstructionCostsSubgroup.path,
        loader: authenticatedLoader(routes.manageDataConstructionCostsSubgroup),
        element: <ManageConstructionCostsSubgroupRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataConstructionOptionsAdd.path,
        loader: authenticatedLoader(routes.manageDataConstructionOptionsAdd),
        element: <ManageConstructionOptionsAddEditRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataConstructionOptionsEdit.path,
        loader: authenticatedLoader(routes.manageDataConstructionOptionsEdit),
        element: <ManageConstructionOptionsAddEditRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataModelSeriesModelsAdd.path,
        loader: authenticatedLoader(routes.manageDataModelSeriesModelsAdd),
        element: <ManageModelSeriesAddEditRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataModelSeriesModelsEdit.path,
        loader: authenticatedLoader(routes.manageDataModelSeriesModelsEdit),
        element: <ManageModelSeriesAddEditRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataSelectionOptionsSubGroup.path,
        loader: authenticatedLoader(routes.manageDataSelectionOptionsSubGroup),
        element: <ManageSelectionOptionsSubgroupRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataSelectionOptionsAdd.path,
        loader: authenticatedLoader(routes.manageDataSelectionOptionsAdd),
        element: <ManageSelectionOptionsAddEditRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataSelectionOptionsEdit.path,
        loader: authenticatedLoader(routes.manageDataSelectionOptionsEdit),
        element: <ManageSelectionOptionsAddEditRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataAttributesDetail.path,
        loader: authenticatedLoader(routes.manageDataAttributesDetail),
        element: <ManageAttributesDetailRoute />,
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataRetailerDetail.path,
        element: <ManageRetailersDetailRoute />,
        loader: authenticatedLoader(routes.manageDataRetailerDetail),
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataConditionalLogicAdd.path,
        element: <ManageConditionalLogicAddEditRoute />,
        loader: authenticatedLoader(routes.manageDataConditionalLogicAdd),
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.manageDataConditionalLogicEdit.path,
        element: <ManageConditionalLogicAddEditRoute />,
        loader: authenticatedLoader(routes.manageDataConditionalLogicEdit),
        ErrorBoundary: RouteErrorBoundary,
      },
      {
        path: routes.quotesAndOrdersDetail.path,
        element: <QuotesAndOrdersDetailRoute />,
        loader: authenticatedLoader(routes.quotesAndOrdersDetail),
        ErrorBoundary: RouteErrorBoundary,
      },
    ],
  },
  {
    path: routes.quotesAndOrders.path,
    element: <QuotesAndOrdersRouteLayout />,
    children: [
      {
        element: <QuotesAndOrdersRoute />,
        index: true,
        loader: authenticatedLoader(routes.quotesAndOrders),
        ErrorBoundary: RouteErrorBoundary,
      },
    ],
  },
  {
    path: routes.reportingAdd.path,
    element: <ReportingAddEditRouteLayout />,
    children: [
      {
        element: <ReportingAddEditRoute />,
        index: true,
        loader: authenticatedLoader(routes.reportingAdd),
        ErrorBoundary: RouteErrorBoundary,
      },
    ],
  },
  {
    path: routes.reportingEdit.path,
    element: <ReportingAddEditRouteLayout />,
    children: [
      {
        element: <ReportingAddEditRoute />,
        index: true,
        loader: authenticatedLoader(routes.reportingEdit),
        ErrorBoundary: RouteErrorBoundary,
      },
    ],
  },
]);
